export function dedupeParts(parts) {
  const dedupedParts = parts.reduce((acc, cur, index) => {
    if (
      acc[cur.partNumber] &&
      acc[cur.partNumber]['partNumber'] === cur.partNumber &&
      acc[cur.partNumber]['description'] === cur.description &&
      acc[cur.partNumber]['condition'] === cur.condition
    ) {
      const cp = acc[cur.partNumber];
      acc[cur.partNumber] = {
        ...cp,
        customer: '(Various)',
      };
      if (acc[cur.partNumber].minimum) {
        acc[cur.partNumber].minimum = Math.min(cp.minimum, cur.minimum);
      }

      if (acc[cur.partNumber].maximum) {
        acc[cur.partNumber].maximum = Math.min(cp.maximum, cur.maximum);
      }

      if (acc[cur.partNumber].average) {
        acc[cur.partNumber].average = Math.round(
          (cp.average + cur.average) / 2
        );
      }

      if (acc[cur.partNumber].count) {
        acc[cur.partNumber].count = cp.count + cur.count;
      }
    } else {
      acc[cur.partNumber] = cur;
    }

    return acc;
  }, []);
  return Object.keys(dedupedParts)
    .map(part => dedupedParts[part])
    .sort((a, b) => b.count - a.count);
}
