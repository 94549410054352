import React from 'react';
import ReDoughnutChart from './charts/reDoughnut';

const OrderBookDonut = ({ orders, isPresentation, subHeading }) => {
  const data = {
    labels: orders.map(order => order.storeman),
    data: orders.map(order => order.total),
  };
  return (
    <div>
      <ReDoughnutChart
        linkTo={'/parts'}
        centerText={'Total'}
        isCurrency={true}
        chartData={data}
        isPresentation={isPresentation}
        headline="Total Orders by Sales People"
        subHeading={subHeading}
      />
    </div>
  );
};

export default OrderBookDonut;
