import React from 'react';
import { currencyFormat } from '../constants';
import ReLineChart from './charts/reLine';

const SalesLine = ({
  dataset,
  animationDuration,
  isPresentation,
  subHeading,
}) => {
  const newData = reformatForLineGraph(dataset);
  return (
    <div>
      <ReLineChart
        isPresentation={isPresentation}
        animationDuration={animationDuration}
        domain={[0, 'auto']}
        heading={'Gross profit per staff member (USD)'}
        dataset={newData}
        tooltipFormatter={currencyFormat}
        subHeading={subHeading}
      />
    </div>
  );
};

function reformatForLineGraph(staff) {
  const refArr = Object.keys(staff.storemen);

  const data = staff.dates.reduce((acc, cur) => {
    const curDate = cur.date;
    const storemen = cur.data.storemen;
    refArr.forEach(storeman => {
      if (typeof storemen[storeman] !== 'undefined') {
        if (!acc[storeman]) acc[storeman] = { label: storeman };
        if (acc[storeman].data) {
          acc[storeman].data.push({ x: curDate, y: storemen[storeman].profits });
        } else {
          acc[storeman].data = [{ x: curDate, y: storemen[storeman].profits }];
        }
      }
    });
    return acc;
  }, {});

  return Object.values(data);
}

export default SalesLine;
