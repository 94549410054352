import React from 'react';
import { fetchUsers } from '../../actions/InitActions';
import { updateUser, deleteUser, createUser } from '../../actions/UserActions';
import { connect } from 'react-redux';
import UserList from '../lists/UserList';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import EditAccountDialog from '../dialogs/EditAccountDialog';
import ResultCard from '../utils/ResultCard';
import ConfirmDialog from '../utils/ConfirmDialog';

const styles = theme => ({
  fab: {
    position: 'absolute',
    right: '48px',
    top: '92px',
  },
  table: {
    padding: '0 !important',
  },
});

class Users extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showEditDialog: false,
      targetUser: {},
      deleteUser: null,
    };
  }

  componentDidMount() {
    const { adminState, userState } = this.props;
    if (adminState.users && adminState.users.length === 0) {
      userState.refresh();
    }
  }

  render() {
    const { adminState, classes } = this.props;
    return (
      <div className={classes.table}>
        <EditAccountDialog
          onClose={() => this.closeDialog()}
          open={this.state.showEditDialog}
          user={this.state.targetUser}
        />
        <Typography align="left" variant="title" gutterBottom={true}>
          User Management
        </Typography>
        <Button
          variant="fab"
          className={classes.fab}
          color="primary"
          onClick={() => this.openCreateDialog()}
        >
          <AddIcon />
        </Button>
        <ResultCard
          context={{
            loading: adminState.loading,
            results: adminState.users,
            error: adminState.error,
          }}
          component={UserList}
          contentProps={{
            className: classes.table,
          }}
          resultsProps={{
            onSelect: this.openEditDialog,
            onDelete: this.openDeleteDialog,
          }}
        />
        <ConfirmDialog
          title={`Deleting ${
            this.state.deleteUser ? this.state.deleteUser.username : ''
          }`}
          open={this.state.deleteUser !== null}
          onConfirm={() => this.handleDeleteUser(this.state.deleteUser)}
          onClose={() => {
            this.setState({ deleteUser: null });
          }}
        >
          Are you sure you would like to delete this user account?
        </ConfirmDialog>
      </div>
    );
  }

  openCreateDialog() {
    this.setState({
      showEditDialog: true,
      targetUser: {},
    });
  }

  closeDialog() {
    this.setState({
      showEditDialog: false,
      targetUser: {},
      deleteUser: null,
    });
  }

  openEditDialog = user => {
    this.setState({
      showEditDialog: true,
      targetUser: user,
    });
  };

  openDeleteDialog = user => {
    this.setState({
      deleteUser: user,
    });
  };

  handleDeleteUser = user => {
    this.props.userState.delete(user);
  };

  handleCreateUser = () => {
    this.closeDialog();
  };

  closeAndRefresh = user => {
    this.closeDialog();
    this.props.userState.refresh();
  };
}

const mapStateToProps = store => {
  return {
    adminState: store.admin,
    updateUserStatus: store.admin.userUpdateStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userState: {
      refresh: () => dispatch(fetchUsers()),
      add: user => dispatch(createUser(user)),
      delete: user => dispatch(deleteUser(user)),
      update: user => dispatch(updateUser(user)),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Users));
