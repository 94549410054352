import * as AircraftType from './utils/aircraftTypes';

/**
 * Breaks down individual aircraft  flags
 * @param model The model returned from the database
 * @param types {number[]} Types to display
 * @return {number[]}
 */
export function getAircraftTypes(model, types) {
  // Unspecified is applied when value is 0.
  // This cannot be checked using a bitwise AND operation
  if (model.aircraft.flags === 0) {
    return [AircraftType.Unspecified];
  }

  return types.filter(type => (model.aircraft.flags & type) === type);
}

export function mapData(sales, types) {
  let data = {};
  sales.forEach(sale => {
    const modelTypes = getAircraftTypes(sale, types);
    modelTypes.forEach(t => {
      data = countAircraftType(data, sale, t);
    });
  });

  return getSortedResult(data, types);
}

/**
 * Preserves a consistent order in which aircraft type labels appear
 * @param data
 * @param types
 * @return {{data: *[], labels: *[]}}
 */
function getSortedResult(data, types) {
  const labels = [];
  const values = [];
  for (let i = 0; i < types.length; i++) {
    const label = AircraftType.labels[types[i]];

    if (data.hasOwnProperty(label)) {
      labels[i] = label;
      values[i] = data[label].total;
    }
  }
  return {
    labels: labels.filter(n => n),
    data: values.filter(n => n),
  };
}

function countAircraftType(data, sale, type) {
  const label = AircraftType.labels[type];
  if ((sale.aircraft.flags & type) === 0 && sale.aircraft.flags !== 0) {
    return data;
  }

  if (data[label]) {
    data[label].count += sale.count;
    data[label].total += sale.total;
  } else {
    data[label] = {
      count: sale.count,
      total: sale.total,
    };
  }

  return data;
}
