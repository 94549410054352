import { combineReducers } from 'redux';
import sales from './sales';
import orders from './orders';
import parts from './parts';
import quotations from './quotations';
import user from './user';
import navigation from './navigation';
import admin from './admin';
import picker from './picker';

const rootReducer = combineReducers({
  navigation,
  sales,
  parts,
  orders,
  quotations,
  user,
  admin,
  picker,
});

export default rootReducer;
