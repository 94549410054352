import React, { Component } from 'react';
import { connect } from 'react-redux';
import Splash from '../pages/Splash';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { mapDispatchToProps } from '../pages/Splash';

export default function(ComposedComponent) {
  class Authenticate extends Component {
    showSplash = false;
    original = this.props.history.location.path;

    componentDidMount() {
      const token =
        localStorage.getItem('token') || sessionStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        this.props.authStoredUser(jwt_decode(token));

        if (this.props.location.pathname !== '/presentation') {
          this.props.loadAll();
        }

        return;
      }

      if (!this.props.isAuthenticated) {
        this.props.history.push(this.original || '/');
      }
    }

    componentWillUpdate(nextProps) {
      this.showSplash = !nextProps.isAuthenticated;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!prevProps.isAuthenticated && !this.showSplash) {
        this.props.history.push(this.original);
      }
    }

    render() {
      return this.showSplash ? (
        <Splash />
      ) : (
        <ComposedComponent {...this.props} />
      );
    }
  }

  const mapStateToProps = store => {
    return {
      isAuthenticated: store.user.user !== null && store.user.loader > 7,
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Authenticate);
}
