const defaultState = {
  loading: false,
  error: null,
  users: [],
  roles: [],
  userUpdateStatus: 0,
};

export default function admin(state = defaultState, action) {
  switch (action.type) {
    case 'ADMIN_USER_LIST_FETCHING':
      return { ...state, loading: true };
    case 'ADMIN_USER_LIST_FULFILLED':
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        roles: action.payload.roles,
        error: null,
      };
    case 'ADMIN_USER_LIST_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: [],
        roles: [],
      };
    case 'USER_CREATE_ERROR':
      return { ...state, userUpdateStatus: 2 };
    case 'USER_UPDATE_ERROR':
      return { ...state, userUpdateStatus: 3 };
    case 'USER_DELETE_ERROR':
      return { ...state, userUpdateStatus: 4 };
    case 'USER_UPDATE_FULFILLED':
      return { ...state, userUpdateStatus: 0 };
    case 'USER_UPDATING':
      return { ...state, userUpdateStatus: 1 };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
