import React from 'react';
import ReLineChart from './charts/reLine';
import { currencyFormat } from '../constants';

const QuotesLine = ({
  dataset,
  isPresentation,
  heading,
  subHeading,
  animationDuration,
}) => {
  const newData = [
    {
      label: 'Value (USD)',
      data: dataset.map(item => ({ x: item.date, y: item.sale_price })),
    },
  ];

  return (
    <div>
      <ReLineChart
        domain={[0, 'auto']}
        margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        heading={heading === undefined ? 'Quotations Total' : heading}
        dataset={newData}
        animationDuration={animationDuration}
        subHeading={subHeading}
        isPresentation={isPresentation}
        tooltipFormatter={currencyFormat}
      />
    </div>
  );
};
export default QuotesLine;
