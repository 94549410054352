import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StaffSalesHistoricalTable from './StaffSalesHistoricalTable';

const StaffSalesHistorical = ({ sales }) => {
  if (!sales.dates) {
    return (
      <Paper>
        <Typography align="left" variant="headline" gutterBottom>
          Staff Sales Totals
        </Typography>
        <Typography variant="button">No data available</Typography>
      </Paper>
    );
  }
  return (
    <Card>
      <CardContent>
        <Typography align="left" variant="headline" gutterBottom>
          Staff Sales Totals
        </Typography>
        <StaffSalesHistoricalTable data={sales} />
      </CardContent>
    </Card>
  );
};

export default StaffSalesHistorical;
