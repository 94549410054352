import React from 'react';
import ReDoughnutChart from './charts/reDoughnut';
import { currencyFormat } from '../constants';

const SalesCustomersDonut = ({ salesCustomers, isPresentation }) => {
  const data = {
    labels: salesCustomers.map(salesCustomers => salesCustomers.customer),
    data: salesCustomers.map(salesCustomers => salesCustomers.total),
  };
  return (
    <div>
      <ReDoughnutChart
        currencySymbol="$"
        isCurrency={true}
        linkTo={'/customers'}
        centerText={'Total'}
        chartData={data}
        isPresentation={isPresentation}
        headline="Top 5 Customers"
        tooltipFormatter={currencyFormat}
      />
    </div>
  );
};

export default SalesCustomersDonut;
