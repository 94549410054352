import { localSave } from '../utils/localPorrage';
import {
  scrubSales,
  scrubStaffSalesTotals,
} from '../components/utils/scrubbers';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { dedupeParts } from '../utils/dedupeParts';
import { formatSectorSales } from '../utils/formatSectorSales';
import { formatStaffSales } from '../utils/formatStaffSales';
import { handleFetchError } from '../utils/handleFetchError';

const API = process.env.REACT_APP_DOMAIN;

// TODO move default args to state.
const defaultArgs = {
  start_date: moment()
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  cohort: 'day',
};

const defaultOrderArgs = {
  cohort: 'day',
};

export function fetchSales(args = defaultArgs) {
  return function(dispatch, getState) {
    const type = 'SALES_FULFILLED';
    let url = `${API}/sales/`;
    dispatch({
      type: 'SALES_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const sales = scrubSales(res.data, args.cohort);
        localSave(type, sales);
        return dispatch({
          type,
          payload: sales,
        });
      })
      .catch(err => handleFetchError('SALES_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchSectorSales(args = defaultArgs) {
  const type = 'SECTOR_SALES_FULFILLED';
  let url = `${API}/sales/sector`;
  return function(dispatch, getState) {
    dispatch({ type: 'SECTOR_SALES_FETCHING' });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const sectorSales = formatSectorSales(res.data);
        localSave(type, sectorSales);
        return dispatch({
          type,
          payload: sectorSales,
        });
      })
      .catch(err => {
        handleFetchError('SECTOR_SALES_ERROR', err, dispatch, type);
      })
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchStaffSales(args = defaultArgs) {
  const type = 'STAFF_SALES_FULFILLED';
  let url = `${API}/sales/staff`;
  return function(dispatch, getState) {
    dispatch({
      type: 'STAFF_SALES_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const staffSales = formatStaffSales(res.data);
        localSave(type, staffSales);
        return dispatch({
          type,
          payload: staffSales,
        });
      })
      .catch(err => handleFetchError('STAFF_SALES_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchStaffProfits(args = defaultArgs) {
  const type = 'STAFF_PROFITS_FULFILLED';
  let url = `${API}/sales/staff/profits`;
  return function(dispatch, getState) {
    dispatch({
      type: 'STAFF_PROFITS_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const staffSales = formatStaffSales(res.data);
        localSave(type, staffSales);
        return dispatch({
          type,
          payload: staffSales,
        });
      })
      .catch(err => handleFetchError('STAFF_PROFITS_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchStaffProfitsSummary(args = defaultArgs) {
  const type = 'STAFF_PROFITS_SUMMARY_FULFILLED';
  let url = `${API}/sales/staff/profits/summary`;
  return function(dispatch, getState) {
    dispatch({
      type: 'STAFF_PROFITS_SUMMARY_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const staffSales = res.data;
        localSave(type, staffSales);
        return dispatch({
          type,
          payload: staffSales,
        });
      })
      .catch(err => handleFetchError('STAFF_PROFITS_SUMMARY_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchStaffSalesSummary(args = defaultArgs) {
  const type = 'STAFF_SALES_SUMMARY_FULFILLED';
  let url = `${API}/sales/staff/summary`;
  return function(dispatch, getState) {
    dispatch({
      type: 'STAFF_SALES_SUMMARY_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const staffSalesTotals = scrubStaffSalesTotals(res.data);
        localSave(type, staffSalesTotals);
        return dispatch({
          type,
          payload: staffSalesTotals,
        });
      })
      .catch(err =>
        handleFetchError('STAFF_SALES_SUMMARY_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchSalesByAircraft(args = defaultArgs) {
  return function(dispatch, getState) {
    const type = 'SALES_AIRCRAFT_FULFILLED';
    let url = `${API}/sales/aircraft`;
    dispatch({ type: 'SALES_AIRCRAFT_FETCHING' });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const sales = scrubSales(res.data, args.cohort);
        localSave(type, sales);
        return dispatch({ type, payload: sales });
      })
      .catch(err =>
        handleFetchError('SALES_AIRCRAFT_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchSalesByCustomers(args = defaultArgs) {
  return function(dispatch, getState) {
    const type = 'SALES_CUSTOMERS_FULFILLED';
    let url = `${API}/sales/customer`;
    dispatch({ type: 'SALES_CUSTOMERS_FETCHING' });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const sales = res.data;
        localSave(type, sales);
        return dispatch({ type, payload: sales });
      })
      .catch(err =>
        handleFetchError('SALES_CUSTOMERS_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchCountrySales(args = defaultArgs) {
  const type = 'COUNTRY_SALES_FULFILLED';
  const url = `${API}/sales/countries`;
  return function(dispatch) {
    dispatch({
      type: 'COUNTRY_SALES_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const sales = res.data;
        localSave(type, sales);
        return dispatch({
          type,
          payload: sales,
        });
      })
      .catch(err =>
        handleFetchError('COUNTRY_SALES_ERROR', err, dispatch, type)
      );
  };
}

export function fetchSalesDetail(args) {
  const type = 'SALES_DETAIL_FULFILLED';
  const url = `${API}/sales/orders/${args.date}`;
  return function(dispatch) {
    dispatch({
      type: 'SALES_DETAIL_FETCHING',
    });
    axios
      .get(url)
      .then(res => {
        const sales = res.data;
        localSave(type, sales);
        return dispatch({
          type,
          payload: sales,
        });
      })
      .catch(err =>
        handleFetchError('SALES_DETAIL_ERROR', err, dispatch, type)
      );
  };
}

export function fetchPartsEnquired(args = defaultArgs, getAll = false) {
  if (!args) args = defaultArgs;
  const type = getAll ? 'PARTS_FULFILLED' : 'PARTS_ALL_FULFILLED';
  let url = `${API}/part/enquired`;
  return function(dispatch, getState) {
    if (getAll) {
      url += '/all';
      dispatch({
        type: 'PARTS_ALL_FETCHING',
      });
    } else {
      dispatch({
        type: 'PARTS_FETCHING',
      });
    }
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = res.data;
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err => handleFetchError('PARTS_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchPartsNotQuoted(args = defaultArgs) {
  if (!args) args = defaultArgs;
  const type = 'PARTS_NOT_QUOTED_FULFILLED';
  let url = `${API}/part/zero-quoted`;
  return function(dispatch, getState) {
    dispatch({
      type: 'PARTS_NOT_QUOTED_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = dedupeParts(res.data);
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err =>
        handleFetchError('PARTS_NOT_QUOTED_ERROR', err, dispatch, type)
      );
  };
}

export function fetchValuations(args = defaultArgs) {
  if (!args) args = defaultArgs;
  let url = `${API}/part/valuation`;
  return function(dispatch, getState) {
    dispatch({
      type: 'PARTS_VALUATIONS_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const valuations = res.data;
        return dispatch({
          type: 'PARTS_VALUATIONS_FULFILLED',
          payload: valuations,
        });
      })
      .catch(err => handleFetchError('PARTS_VALUATIONS_ERROR', err, dispatch))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchOrders(params = defaultOrderArgs) {
  const subtype = params['subtype'] ? params['subtype'] + '_' : '';
  delete params['subtype'];
  const type = subtype + 'ORDERS_FULFILLED';
  let url = `${API}/order/`;
  return function(dispatch, getState) {
    dispatch({
      type: subtype + 'ORDERS_FETCHING',
    });
    axios
      .get(url, {
        params,
      })
      .then(res => {
        const orders = res.data;
        localSave(type, orders);
        return dispatch({
          type,
          payload: orders,
        });
      })
      .catch(err => handleFetchError(subtype + 'ORDERS_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchOrdersByStoreman(args = defaultOrderArgs) {
  const type = 'ORDERS_STOREMAN_FULFILLED';
  const url = `${API}/order/storeman`;
  return function(dispatch, getState) {
    dispatch({
      type: 'ORDERS_STOREMAN_FETCHING',
    });
    axios
      .get(url, {
        args,
      })
      .then(res => {
        const orders = res.data;
        localSave(type, orders);
        return dispatch({
          type,
          payload: orders,
        });
      })
      .catch(err =>
        handleFetchError('ORDERS_STOREMAN_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchOrdersBySupplier(args = defaultOrderArgs) {
  const type = 'ORDERS_SUPPLIER_FULFILLED';
  const url = `${API}/order/supplier`;
  return function(dispatch, getState) {
    dispatch({
      type: 'ORDERS_SUPPLIER_FETCHING',
    });
    axios
      .get(url, {
        args,
      })
      .then(res => {
        const orders = res.data;
        localSave(type, orders);
        return dispatch({
          type,
          payload: orders,
        });
      })
      .catch(err =>
        handleFetchError('ORDERS_SUPPLIER_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchOrderBalance() {
  const url = `${API}/order/balance`;
  const type = 'ORDERS_BALANCE_FULFILLED';
  return function(dispatch) {
    dispatch({
      type: 'ORDERS_BALANCE_FETCHING',
    });
    axios
      .get(url)
      .then(res => {
        localSave(type, res.data);
        return dispatch({
          type,
          payload: res.data,
        });
      })
      .catch(err =>
        handleFetchError('ORDERS_BALANCE_ERROR', err, dispatch, type)
      );
  };
}

export function getPartsQuoted(args = defaultArgs) {
  const type = 'PARTS_QUOTED_FULFILLED';
  let url = `${API}/part/quoted`;
  return function(dispatch, getState) {
    dispatch({
      type: 'PARTS_QUOTED_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = dedupeParts(res.data);
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err => handleFetchError('PARTS_QUOTED_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function getPartsNotQuoted(args = defaultArgs) {
  if (!args) args = defaultArgs;
  const type = 'PARTS_NOT_QUOTED_FULFILLED';
  let url = `${API}/part/zero-quoted`;
  return function(dispatch, getState) {
    dispatch({
      type: 'PARTS_NOT_QUOTED_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = dedupeParts(res.data);
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err =>
        handleFetchError('PARTS_NOT_QUOTED_ERROR', err, dispatch, type)
      );
  };
}

export function fetchQuotations(args = defaultArgs) {
  const type = 'QUOTATIONS_FULFILLED';
  let url = `${API}/quote/out/`;
  return function(dispatch, getState) {
    dispatch({
      type: 'QUOTATIONS_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = res.data;
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err => handleFetchError('QUOTATIONS_ERROR', err, dispatch, type))
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchQuotationsSummary(args = defaultArgs) {
  const type = 'QUOTATIONS_SUMMARY_FULFILLED';
  const url = `${API}/quote/out/total`;
  return function(dispatch, getState) {
    dispatch({
      type: 'QUOTATIONS_SUMMARY_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const parts = res.data;
        localSave(type, parts);
        return dispatch({
          type,
          payload: parts,
        });
      })
      .catch(err =>
        handleFetchError('QUOTATIONS_SUMMARY_ERROR', err, dispatch, type)
      )
      .finally(_ => setLoader(dispatch, getState().user.loader + 1));
  };
}

export function fetchRecentQuotations(args = defaultArgs) {
  const url = `${API}/quote/out/recent`;
  const type = 'RECENT_QUOTATIONS_FULFILLED';
  return function(dispatch) {
    dispatch({
      type: 'RECENT_QUOTATIONS_FETCHING',
    });
    axios
      .get(url, {
        params: args,
      })
      .then(res => {
        const quotations = res.data;
        localSave(type, quotations);
        return dispatch({
          type,
          payload: quotations,
        });
      })
      .catch(err =>
        handleFetchError('RECENT_QUOTATIONS_ERROR', err, dispatch, type)
      );
  };
}

export function fetchUsers() {
  const type = 'ADMIN_USER_LIST_FULFILLED';
  const url = `${API}/user/`;
  return function(dispatch) {
    dispatch({
      type: 'ADMIN_USER_LIST_FETCHING',
    });
    axios
      .get(url)
      .then(res => {
        const admins = res.data;
        return dispatch({
          type,
          payload: admins,
        });
      })
      .catch(err =>
        handleFetchError('ADMIN_USER_LIST_ERROR', err, dispatch, type)
      );
  };
}

export function authUser(credentials) {
  const url = `${API}/auth`;
  return dispatch => {
    dispatch({
      type: 'AUTH_IN_PROGRESS',
    });
    axios
      .post(url, credentials)
      .then(response => {
        if (credentials.saveUser) {
          localStorage.setItem('token', response.data.token);
        } else {
          sessionStorage.setItem('token', response.data.token);
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${
          response.data.token
        }`;
        const { user, scopes } = jwt_decode(response.data.token);
        dispatch({
          type: 'AUTH_SUCCESS',
          user,
          scopes,
        });
      })
      .catch(err => {
        dispatch({
          type: 'AUTH_FAILED',
        });
      });
  };
}

const setLoader = function(dispatch, value) {
  dispatch({
    type: 'UPDATE_LOADER',
    payload: value,
  });
};
