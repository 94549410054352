import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PartsTable from '../tables/PartsTable';
import Loader from '../Loader';
import Typography from '@material-ui/core/Typography';

class Staff extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const {
      partsNotQuoted,
      partsQuoted,
      notQuotedLoading,
      quotedLoading,
    } = this.props;
    if (
      !partsNotQuoted.length ||
      !partsQuoted.length
    ) {
      return <Loader />;
    }
    const notQuotedExclude = ['date', 'minimum', 'maximum', 'average', 'total'];
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Parts
        </Typography>
        <Grid container spacing={24} justify="flex-start" direction="row">
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                {quotedLoading ? (
                  <React.Fragment>
                    <Loader />
                    <PartsTable data={partsQuoted} heading="Top Quoted" />
                  </React.Fragment>
                ) : (
                  <PartsTable data={partsQuoted} heading="Top Quoted" />
                )}
              </CardContent>
            </Card>
          </Grid>
          {/* :: Removed until requested by Jez
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                {enquiredLoading ? (
                  <React.Fragment>
                    <Loader />
                  </React.Fragment>
                ) : (
                  <PartsTable data={partsEnquired} heading="Top Enquired" />
                )}
              </CardContent>
            </Card>
          </Grid>
          */}
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                {notQuotedLoading ? (
                  <React.Fragment>
                    <Loader />
                    <PartsTable
                      data={partsNotQuoted}
                      heading="Top Not Quoted"
                      excludedFields={notQuotedExclude}
                    />
                  </React.Fragment>
                ) : (
                  <PartsTable data={partsNotQuoted} heading="Top Not Quoted"
                              excludedFields={notQuotedExclude}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'parts' }),
  };
};

export default connect(
  store => {
    return {
      partsEnquired: store.parts.partsEnquiredAll,
      partsNotQuoted: store.parts.partsNotQuoted,
      partsQuoted: store.parts.partsQuoted,
      enquiredLoading: store.parts.partsEnquiredAllLoading,
      notQuotedLoading: store.parts.partsNotQuotedLoading,
      quotedLoading: store.parts.partsQuotedLoading,
    };
  },
  mapDispatchToProps
)(Staff);
