const defaultState = {
  authenticating: false,
  loginFailed: false,
  user: null,
  loader: 0,
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'AUTH_IN_PROGRESS':
      return { ...state, authenticating: true, loginFailed: false };
    case 'AUTH_SUCCESS':
      return {
        authenticating: false,
        loginFailed: false,
        user: action.user,
        scopes: action.scopes,
        loader: 1,
      };
    case 'UPDATE_LOADER':
      return { ...state, loader: action.payload };
    case 'AUTH_FAILED':
      document
        .querySelector('.Splash__card')
        .classList.remove('animated', 'shake');
      document.getElementById('f_password').focus();
      return { ...state, loginFailed: true, authenticating: false };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
