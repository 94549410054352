const defaultState = {
  partsEnquired: [],
  partsEnquiredAll: [],
  partsQuoted: [],
  partsValuations: { history: [] },
  partsEnquiredLoading: false,
  partsEnquiredAllLoading: false,
  partsQuotedLoading: false,
  partsValuationsLoading: false,
  partsNotQuoted: [],
  partsNotQuotedLoading: false,
};

export default function parts(state = defaultState, action) {
  switch (action.type) {
    case 'PARTS_FETCHING':
      return { ...state, partsEnquiredLoading: true };
    case 'PARTS_FULFILLED':
      return {
        ...state,
        partsEnquiredLoading: false,
        partsEnquired: action.payload,
      };
    case 'PARTS_ERROR':
      return {
        ...state,
        partsEnquiredLoading: false,
        error: action.payload,
        partsEnquired: [],
      };
    case 'PARTS_ALL_FETCHING':
      return { ...state, partsEnquiredAllLoading: true };
    case 'PARTS_ALL_FULFILLED':
      return {
        ...state,
        partsEnquiredAllLoading: false,
        partsEnquiredAll: action.payload,
      };
    case 'PARTS_ALL_ERROR':
      return {
        ...state,
        partsEnquiredAllLoading: false,
        error: action.payload,
        partsEnquired: [],
      };
    case 'PARTS_QUOTED_FETCHING':
      return { ...state, partsQuotedLoading: true };
    case 'PARTS_QUOTED_FULFILLED':
      return {
        ...state,
        partsQuotedLoading: false,
        partsQuoted: action.payload,
      };
    case 'PARTS_QUOTED_ERROR':
      return {
        ...state,
        partsQuotedLoading: false,
        error: action.payload,
        partsQuoted: [],
      };
    case 'PARTS_VALUATIONS_FETCHING':
      return { ...state, partsValuationsLoading: true };
    case 'PARTS_VALUATIONS_FULFILLED':
      return {
        ...state,
        partsValuationsLoading: false,
        partsValuations: action.payload,
      };
    case 'PARTS_VALUATIONS_ERROR':
      return {
        ...state,
        partsValuationsLoading: false,
        error: action.payload,
        partsValuations: [],
      };
    case 'PARTS_NOT_QUOTED_FETCHING':
      return { ...state, partsNotQuotedLoading: true };
    case '':
      return {
        ...state,
        partsNotQuotedLoading: false,
        partsNotQuoted: action.payload,
      };
    case 'PARTS_NOT_QUOTED_ERROR':
      return {
        ...state,
        partsNotQuotedLoading: false,
        error: action.payload,
        partsNotQuoted: [],
      };
    case 'PARTS_NOT_QUOTED_FULFILLED':
      return {
        ...state,
        partsNotQuotedLoading: false,
        partsNotQuoted: action.payload,
      };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
