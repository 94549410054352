import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import { Provider } from 'react-redux';
import store from './store';
import requireLogin from './components/utils/requireLogin';
import SentryBoundary from './components/utils/SentryBoundary';
import * as Sentry from '@sentry/browser';
import { BUILD_INFO } from './constants';
import axios from '../node_modules/axios/index';
import '@babel/polyfill';

class App extends Component {
  constructor() {
    super();
    const sentryOptions = {
      dsn: 'https://30552dd24b524226b2d1283d8992e293@sentry.io/1394950',
    };

    if (BUILD_INFO.build !== '')
      sentryOptions.release = `perkins-reflects@${BUILD_INFO.build}`;

    Sentry.init(sentryOptions);
    const token =
      localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }

  render() {
    return (
      <SentryBoundary>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route path="/:filter?" component={requireLogin(Layout)} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </SentryBoundary>
    );
  }
}

export default App;
