import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import 'typeface-open-sans';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { defaults } from 'react-chartjs-2';

defaults.global.defaultFontColor = '#575E7B';
defaults.global.defaultFontFamily = 'Open Sans';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#575E7B',
    },
    secondary: {
      main: '#323D64',
    },
    background: {
      default: '#F0F3F6',
    },
  },
  typography: {
    fontFamily: ['Open Sans'],
    display1: {
      fontSize: '1.65rem',
      marginBottom: '1em !important',
    },
  },
  overrides: {
    MuiList: {
      root: {
        textAlign: 'center',
        fontSize: '0.75rem',
        color: '#575E7B',
      },
    },
    MuiListItem: {
      root: {
        fontSize: '0.75rem !important',
        color: '#575E7B !important',
      },
    },
    MuiMenuItem: {
      selected: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFF !important',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '0 !important',
        flex: 1,
      },
      elevation8: {
        // boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'

        boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.15)',
      },

      elevation4: {
        boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.04)',
      },
      elevation1: {
        boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.04)',
      },
    },
    MuiTypography: {
      headline: {
        fontSize: '1.13rem',
      },
      root: {
        color: '#575E7B !important',
      },
    },
    MuiTable: {
      root: {
        minWidth: '1px !important',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '0.75rem !important',
        color: '#575E7B !important',
        padding: '4px 8px 4px 8px',
        textAlign: 'left !important',
      },
      head: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#F0F3F6',
        textAlign: 'left !important',
      },
    },
    MuiTableRow: {
      root: {
        height: '36px',
      },
      head: {
        height: '36px',
      },
    },
    MuiGrid: {
      item: {
        display: 'flex',
      },
    },
    MuiTablePagination: {
      spacer: {
        flex: 'none',
      },
      select: {
        paddingRight: '23px',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'white',
      },
      message: {
        color: '#333333 !important',
      },
    },

    MuiInput: {
      input: {
        transition: '0.2s ease-out',
        '&:hover': {
          backgroundColor: 'rgba(2, 157, 175, 0.07)',
        },
        '&:focused': {
          backgroundColor: 'rgba(2, 157, 175, 0.07)',
        },
      },
      underline: {
        '&:after': {
          borderBottom: `1px solid #20778F`,
        },
        '&$focused:after': {
          borderBottomColor: `#20778F`,
        },
        '&$error:after': {
          borderBottomColor: `red`,
        },
        '&:before': {
          borderBottom: '1px solid rgba(194, 194, 194, 1)',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          // borderBottom: `1px solid #029DAF`,
          borderBottom: '1px solid rgba(104, 104, 104, 1)',
        },
        '&$disabled:before': {
          borderBottom: '1px solid rgba(194, 194, 194, 1)',
        },
      },
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
);
unregister();
