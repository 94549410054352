import moment from 'moment';

export default function picker(
  state = {
    start_date: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    cohort: 'day',
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_PICKER':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
