import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  ComposedChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { longIntFormat } from '../../constants';

const ReBarChart = ({
  chartData,
  heading,
  animationDuration,
  isPresentation,
  subHeading,
  isCurrency
}) => {
  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        backgroundColor: [
          'rgba(2, 157, 175, 1)',
          'rgba(28, 204, 191, 1)',
          'rgba(248, 172, 27, 1)',
          'rgba(248, 138, 43, 1)',
          'rgba(38, 173, 222, 1)',
          'rgba(28, 199, 120, 1)',
          'rgba(211, 217, 39, 1)',
          'rgba(163, 161, 251, 1)',
          'rgba(138, 139, 168, 1)',
          'rgba(55, 149, 230, 1)',
          'rgba(2, 175, 148, 1)',
          'rgba(248, 97, 62, 1)',
          'rgba(194, 70, 224, 1)',
          'rgba(144, 190, 142, 1)',
          'rgba(245, 97, 166, 1)',
        ],
      },
    ],
  };

  const formatData = data => {
    const formattedData = data.labels.map((value, index) => {
      return {
        name: value,
        y: parseFloat(data.datasets[0].data[index]),
        fill: data.datasets[0].backgroundColor[index],
      };
    });
    return formattedData;
  };

  const containerStyle = {
    paddingBottom: '60%',
    position: 'relative',
  };

  const CustomTooltip = args => {
    return (
      <div className="custom-chart-tooltip">
        {args.payload.map((value, index) => {
          return (
            <div key={index}>
              <p
                style={{
                  color: value.payload.fill,
                  fontWeight: 'bold',
                  transition: 'color 0.5s',
                }}
              >
                {value.payload.name}
              </p>
              <p>{isCurrency ? '$' : ''}{longIntFormat.format(value.value)}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {isPresentation ? (
        <div>
          <Typography
            align="center"
            variant="display2"
            style={{ marginBottom: subHeading !== undefined ? '0.5em' : '1em' }}
            children={heading}
          />
          {subHeading !== undefined && (
            <Typography
              align="center"
              variant="display1"
              style={{ marginBottom: '1em' }}
            >
              {subHeading}
            </Typography>
          )}
        </div>
      ) : (
        <Typography
          align="left"
          variant="headline"
          gutterBottom
          children={heading}
        />
      )}
      <div className="chart-wrap" style={containerStyle}>
        <ResponsiveContainer>
          <ComposedChart
            barGap={1}
            data={formatData(data)}
            margin={
              isPresentation
                ? { top: 20, right: 0, left: 0, bottom: 100 }
                : { top: 5, right: 30, left: -15, bottom: 5 }
            }
          >
            <CartesianGrid
              stroke="#EAF0F4"
              strokeWidth={isPresentation ? 2 : 1}
            />
            <Bar
              animationDuration={
                animationDuration === undefined ? 1500 : animationDuration
              }
              dataKey="y"
            />

            <XAxis
              interval={0}
              minTickGap={isPresentation ? 70 : 5}
              strokeWidth={isPresentation ? 2 : 1}
              stroke="#575E7B"
              dataKey="name"
              tick={{
                fill: '#575E7B',
                fontFamily: 'Open Sans',
                fontSize: isPresentation ? 24 : 10,
                fontWeight: isPresentation ? 700 : 400,
              }}
              angle={-35}
              textAnchor="end"
            />

            <YAxis
              strokeWidth={isPresentation ? 2 : 1}
              tick={{
                fontFamily: 'Open Sans',
                fontSize: isPresentation ? 28 : 10,
                fill: '#575E7B',
                fontWeight: isPresentation ? 700 : 400,
              }}
              stroke="#575E7B"
            />

            <Tooltip content={<CustomTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReBarChart;
