export function formatSectorSales(data) {
  return data.reduce(
    (acc, cur) => {
      if (cur.isMilitary === 'Y') {
        acc.Military = acc.Military + cur.total;
      } else {
        acc.Commercial = acc.Commercial + cur.total;
      }
      return acc;
    },
    { Military: 0, Commercial: 0 }
  );
}
