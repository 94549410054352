import * as React from 'react';
import * as Sentry from '@sentry/browser';
import {
  Button,
  Typography,
} from '@material-ui/core';

class SentryBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error && process.env.NODE_ENV === 'production') {
      //render fallback UI
      return (
        <div style={{
          marginTop: '80px',
          textAlign: 'center'
        }}>
          <img src="/logo.png" alt="Perkins Reflects" />
          <Typography variant="headline">An error has occurred</Typography><br/>
          <Typography variant="body1">
              Application developers have been notified of the issue.
          </Typography><br/>
          <Button onClick={() => Sentry.showReportDialog()}>
            Report feedback
          </Button>
          <Button
            onClick={() => {
              this.setState({ error: null });
              this.props.history.back();
            }}
          >
            Ignore and continue anyway
          </Button>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryBoundary;
