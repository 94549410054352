import React from 'react';

export const CustomHomeIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="Path_357"
      data-name="Path 357"
      d="M15.581,5.186l-7-5a1,1,0,0,0-1.162,0l-7,5A1,1,0,0,0,1.581,6.814L2,6.515V15a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1V6.515A1.7,1.7,0,0,0,15,7a1,1,0,0,0,.582-1.814ZM12,14H10V11a2,2,0,0,0-4,0v3H4V5.086L8,2.229l4,2.857Z"
    />
  );
};
export const CustomSalesIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="Union_8"
      d="M13,16c-0.5,0-1-0.4-1-0.9c0,0,0-0.1,0-0.1V6c0-0.5,0.4-1,0.9-1c0,0,0.1,0,0.1,0h2
	c0.5,0,1,0.4,1,0.9c0,0,0,0.1,0,0.1v9c0,0.5-0.4,1-0.9,1c0,0-0.1,0-0.1,0H13z M7,16c-0.5,0-1-0.4-1-0.9c0,0,0-0.1,0-0.1V1
	c0-0.5,0.4-1,0.9-1C6.9,0,7,0,7,0h2c0.5,0,1,0.4,1,0.9c0,0,0,0.1,0,0.1v14c0,0.5-0.4,1-0.9,1c0,0-0.1,0-0.1,0H7z M1,16
	c-0.5,0-1-0.4-1-0.9c0,0,0-0.1,0-0.1v-4c0-0.5,0.4-1,0.9-1c0,0,0.1,0,0.1,0h2c0.5,0,1,0.4,1,0.9c0,0,0,0.1,0,0.1v4
	c0,0.5-0.4,1-0.9,1c0,0-0.1,0-0.1,0H1z"
    />
  );
};
export const CustomStaffIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="Union_10"
      d="M0,16c0-3.3,2.7-6,6-6h2c3.3,0,6,2.7,6,6H0z M2.6,14h8.9c-0.7-1.2-2.1-2-3.5-2H6.1
	C4.7,12,3.3,12.8,2.6,14z M3,5V4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4v1c0,2.2-1.8,4-4,4C4.8,9,3,7.2,3,5z M5,4v1c0,1.1,0.9,2,2,2
	c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2C5.9,2,5,2.9,5,4z"
    />
  );
};
export const CustomQuoteIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="Path_102"
      d="M13,0H1C0.5,0,0,0.4,0,0.9C0,0.9,0,1,0,1v15l3-2l2,2l2-2l2,2l2-2l3,2V1c0-0.5-0.4-1-0.9-1
	C13.1,0,13,0,13,0z M11,10H3V8h8V10z M11,6H3V4h8V6z"
    />
  );
};

export const CustomStockIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="np_truck_888654_000000"
      d="M7.1,0v2.4H4.9c-1.2,0-1.6,1.3-1.8,1.8L2.5,5.9H2.4c-1,0-1.7,0.7-1.8,1.7
	c0,0,0,0,0,0.1v1.2H0v2.4h2.4c-0.1,1.3,1,2.4,2.3,2.4s2.4-1,2.4-2.3c0-0.1,0-0.1,0-0.2h5.9c-0.1,1.3,1,2.4,2.3,2.4
	c1.3,0.1,2.4-1,2.4-2.3c0-0.1,0-0.1,0-0.2H20V0H7.1z M3.9,4.4c0.2-0.5,0.5-1.2,1-1.2h2.1v2.6H3.5L3.9,4.4z"
    />
  );
};

export const CustomPartsIcon = () => {
  return (
    <path
      transform="translate(4,4)"
      id="Union_9"
      d="M14,16V0h2v16H14z M0,16V0h2v16H0z M11,11V0h2v11H11z M6,11V0h4v11H6z M3,11V0h2v11H3z"
    />
  );
};

export const CustomCollapseIcon = () => (
  <path
    fill="#000000"
    d="M16.59,5.59L18,7L12,13L6,7L7.41,5.59L12,10.17L16.59,5.59M16.59,11.59L18,13L12,19L6,13L7.41,11.59L12,16.17L16.59,11.59Z"
  />
);

export const CustomAccountIcon = () => (
  <path
    className="account_icon"
    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
  />
);

export const CustomEditIcon = () => (
  <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
);

export const CustomLogoutIcon = () => (
  <g transform="translate(22.193 16.367) rotate(180)">
    <path
      className="a"
      d="M21.269,11.249c-.064.051-.1.077-.136.107-.324.279-.645.563-.972.837a.308.308,0,0,0-.118.26q-.011.995-.035,1.989a.625.625,0,0,1-.677.682c-.075,0-.15.006-.224.006q-3.112,0-6.225.006c-.786,0-1.572-.014-2.358-.027a.572.572,0,0,1-.577-.461h0a1.178,1.178,0,0,1-.02-.256C9.919,12.915,9.9,11.439,9.9,9.963q0-3.978.024-7.957a1.117,1.117,0,0,1,.027-.284c.083-.309.288-.448.684-.45,1.145-.011,2.288-.023,3.433-.023q2.639,0,5.278.017a.606.606,0,0,1,.658.663c.018.673.026,1.347.041,2.021a.256.256,0,0,0,.049.175c.375.333.757.659,1.16,1.007.005-.052.01-.073.01-.093q0-1.6,0-3.208A1.725,1.725,0,0,0,20.67.51,1.75,1.75,0,0,0,19.415,0c-.673.015-1.347,0-2.022,0-2.24,0-4.481.008-6.722.006a1.882,1.882,0,0,0-1.322.43A1.824,1.824,0,0,0,8.66,1.855c.005,2.118-.008,4.235,0,6.353s.02,4.235.024,6.353a1.7,1.7,0,0,0,.576,1.307,1.757,1.757,0,0,0,1.241.5c3.043-.01,6.085-.005,9.128-.008a.918.918,0,0,0,.3-.047,1.866,1.866,0,0,0,1.343-1.862c.009-.824,0-1.647.005-2.47,0-.233,0-.466,0-.731Z"
      transform="translate(0 0)"
    />
    <path
      className="a"
      d="M40.561,28.075l-3.628-3.134a.332.332,0,0,0-.327-.072.4.4,0,0,0-.322.375c-.022.3-.027.595-.032.894s0,.625,0,.945h-.184l-3.577,0h0a.644.644,0,0,0-.654.657v2.5a.633.633,0,0,0,.619.615c1.183,0,2.364-.006,3.545-.006h.24v.231c.006.448.01.9.019,1.351h0a1.45,1.45,0,0,0,.029.288.436.436,0,0,0,.72.242l4.369-3.8c.067-.058.128-.122.2-.189-.051-.049-.088-.086-.128-.12-.3-.262-.59-.526-.889-.785Z"
      transform="translate(-19.385 -20.788)"
    />
  </g>
);

export const CustomReleaseNotesIcon = () => (
  <g id="np_notes_557502_000000" transform="translate(-5.73 -12.323)">
    <path id="Path_392" data-name="Path 392" d="M26.779,27.684H23.015V14.974a2.685,2.685,0,0,0-2.773-2.651H8.5A2.846,2.846,0,0,0,5.73,15.165a.593.593,0,0,0,.593.593h3.764V28.278a2.776,2.776,0,0,0,2.773,2.773H24.6a2.776,2.776,0,0,0,2.772-2.773.592.592,0,0,0-.592-.594ZM7.027,14.572A1.613,1.613,0,0,1,8.5,13.51,1.587,1.587,0,0,1,10,14.572Zm7.419,13.706a1.585,1.585,0,0,1-3.171,0V15.1a2.759,2.759,0,0,0-.5-1.585h9.467a1.5,1.5,0,0,1,1.585,1.464v12.71H15.04a.593.593,0,0,0-.594.593ZM24.6,29.863H15.134a2.764,2.764,0,0,0,.436-.991h10.5a1.589,1.589,0,0,1-1.47.991Z" fill="#fff"/>
    <path id="Path_393" data-name="Path 393" d="M42.6,31.352H36.754a.593.593,0,1,0,0,1.186H42.6a.593.593,0,1,0,0-1.186Z" transform="translate(-22.941 -14.345)" fill="#fff"/>
    <path id="Path_394" data-name="Path 394" d="M42.6,44.148H36.754a.593.593,0,1,0,0,1.186H42.6a.593.593,0,1,0,0-1.186Z" transform="translate(-22.941 -23.992)" fill="#fff"/>
    <path id="Path_395" data-name="Path 395" d="M42.6,56.945H36.754a.593.593,0,1,0,0,1.186H42.6a.593.593,0,0,0,0-1.186Z" transform="translate(-22.941 -33.639)" fill="#fff"/>
  </g>
);

export const CustomPresentationIcon = () => (
  <path d="M2,3H10A2,2 0 0,1 12,1A2,2 0 0,1 14,3H22V5H21V16H15.25L17,22H15L13.25,16H10.75L9,22H7L8.75,16H3V5H2V3M5,5V14H19V5H5M11.85,11.85C11.76,11.94 11.64,12 11.5,12A0.5,0.5 0 0,1 11,11.5V7.5A0.5,0.5 0 0,1 11.5,7C11.64,7 11.76,7.06 11.85,7.15L13.25,8.54C13.57,8.86 13.89,9.18 13.89,9.5C13.89,9.82 13.57,10.14 13.25,10.46L11.85,11.85Z" />
);

export const CustomCustomersIcon = () => (
  <g id="np_handshake_2185218_000000" transform="translate(-2.5 -13)">
    <path id="Path_392" data-name="Path 392" d="M49.145,75.1,48.1,76.118l.3.3a.729.729,0,1,0,1.031-1.031Z" transform="translate(-36.065 -44.46)" fill="#8a8ba8"/>
    <path id="Path_393" data-name="Path 393" d="M13.649,22.215l-3.158,2.319a1.664,1.664,0,0,1-1.817-2.782c.782-.658,2.5-2.1,2.787-2.346a7.353,7.353,0,0,0-4.229-.228,5.5,5.5,0,0,1-.843.121c-.05,0-.1,0-.153,0s-.132,0-.2-.007A3.953,3.953,0,0,1,4.5,18.9a.2.2,0,0,0-.249.065A9.886,9.886,0,0,0,2.5,24.6a9.759,9.759,0,0,0,.075,1.215.329.329,0,0,0,.2.264L4.29,26.7a.064.064,0,0,0,.071-.015c.169-.172,1-1.006,1-1.006a1.322,1.322,0,0,1,1.192-.364A1.261,1.261,0,0,1,7,25.487a1.319,1.319,0,0,1,.613.979,1.323,1.323,0,0,1,1.043-.132,1.292,1.292,0,0,1,.291.123,1.327,1.327,0,0,1,.653,1,1.323,1.323,0,0,1,1.043-.132,1.292,1.292,0,0,1,.291.123,1.327,1.327,0,0,1,.653,1,1.324,1.324,0,0,1,1.609.2,1.325,1.325,0,0,1,.272,1.472l.868.886a.729.729,0,0,0,1.031-1.031l-3.31-3.325a.327.327,0,0,1,.462-.462c.008.008.015.019.023.027l0,0,3.6,3.652a.729.729,0,1,0,1.031-1.031l-3.568-3.636a.327.327,0,0,1,.462-.462l3.664,3.738a.728.728,0,1,0,1.031-1.029Z" transform="translate(0 0)" fill="#8a8ba8"/>
    <path id="Path_394" data-name="Path 394" d="M14.674,53.584a.728.728,0,1,0-1.029-1.031l-.993.993a.728.728,0,1,0,1.029,1.031Z" transform="translate(-7.859 -26.459)" fill="#8a8ba8"/>
    <path id="Path_395" data-name="Path 395" d="M22.243,58.332a.728.728,0,0,0-1.036-1.024L19.7,58.782a.728.728,0,1,0,1.029,1.031l1.506-1.475.007-.007Z" transform="translate(-13.435 -30.219)" fill="#8a8ba8"/>
    <path id="Path_396" data-name="Path 396" d="M29.981,62.751a.728.728,0,0,0-1.22-.7l-2.02,1.953a.728.728,0,1,0,1.029,1.031l2.02-1.953a.719.719,0,0,0,.19-.332Z" transform="translate(-19.002 -33.972)" fill="#8a8ba8"/>
    <path id="Path_397" data-name="Path 397" d="M37.536,67.84a.728.728,0,0,0-.981-1.074c-.016.015-.033.029-.048.044l-2.484,2.417a.728.728,0,1,0,1.029,1.031Z" transform="translate(-24.761 -37.734)" fill="#8a8ba8"/>
    <path id="Path_398" data-name="Path 398" d="M43.813,19.986a.265.265,0,0,0-.4-.059A3.974,3.974,0,0,1,40,20.868c-.1-.021-.207-.044-.31-.074s-.2-.06-.3-.1a9.813,9.813,0,0,0-3-.748,1.78,1.78,0,0,0-1.194.452q-.129.1-.278.213c-.069.055-2.83,2.38-2.83,2.38a1.068,1.068,0,0,0,.623,1.936,1.047,1.047,0,0,0,.529-.142l.123-.09,3.123-2.3a.333.333,0,0,1,.469,0l4.858,4.858a.335.335,0,0,0,.295.092l3.121-.587a.127.127,0,0,0,.1-.111,9.881,9.881,0,0,0,.082-1.28,10.584,10.584,0,0,0-1.6-5.39Z" transform="translate(-23.044 -0.772)" fill="#8a8ba8"/>
  </g>
);