import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableSimple from './tables/Simple';

const StaffProfitsTotals = ({ data }) => {
  if (!data) {
    return (
      <Paper>
        <Typography variant="headline" gutterBottom>
          Gross profit per staff member (USD)
        </Typography>
        <Typography variant="button">No data available</Typography>
      </Paper>
    );
  }

  let rows = [];

  for (let storeman in data){
    rows[storeman.storeman] = {
      storeman: storeman.storeman,
      profits: storeman.profits
    };
  }

  return (
    <TableSimple
      data={data}
      currencyColumn={1}
      heading="Gross profit per staff member (USD)"
      thead="foo"
      columnCount={2}
    />
  );
};

export default StaffProfitsTotals;
