import * as React from 'react';
import { BUILD_INFO } from '../../constants';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  customIcon: {
    marginRight: '16px',
    height: '14px',
    verticalAlign: 'sub',
  },
  root: {
    marginTop: '24px',
    fontWeight: 100,
    fontSize: '12px',
    textAlign: 'center',
    '@media (max-width: 1280px)': {
      display: 'none'
    },
  },
});

const GitIcon = () => (
  <path
    fill="#000000"
    d="M2.6,10.59L8.38,4.8L10.07,6.5C9.83,7.35 10.22,8.28 11,8.73V14.27C10.4,14.61 10,15.26 10,16A2,2 0 0,0 12,18A2,2 0 0,0 14,16C14,15.26 13.6,14.61 13,14.27V9.41L15.07,11.5C15,11.65 15,11.82 15,12A2,2 0 0,0 17,14A2,2 0 0,0 19,12A2,2 0 0,0 17,10C16.82,10 16.65,10 16.5,10.07L13.93,7.5C14.19,6.57 13.71,5.55 12.78,5.16C12.35,5 11.9,4.96 11.5,5.07L9.8,3.38L10.59,2.6C11.37,1.81 12.63,1.81 13.41,2.6L21.4,10.59C22.19,11.37 22.19,12.63 21.4,13.41L13.41,21.4C12.63,22.19 11.37,22.19 10.59,21.4L2.6,13.41C1.81,12.63 1.81,11.37 2.6,10.59Z"
  />
);

class VersionInfo extends React.Component {
  constructor(props) {
    super(props);
    this.details =
      `${BUILD_INFO.agent.name}@${BUILD_INFO}\n` +
      `Node ${BUILD_INFO.agent.nodejs} on ${BUILD_INFO.agent.os}`;
  }

  render() {
    const { classes } = this.props;

    if (BUILD_INFO.build === '')
      return <div className={classes.root}>Development Build</div>;

    return (
      <div className={classes.root}>
        {BUILD_INFO.build}
        <br />
        <SvgIcon className={classes.customIcon}>
          <GitIcon />
        </SvgIcon>
        {BUILD_INFO.branch}@{BUILD_INFO.commit.substring(0, 7)}
        <br />
        <span title={this.details}>{BUILD_INFO.timestamp}</span>
      </div>
    );
  }
}

export default withStyles(styles)(VersionInfo);
