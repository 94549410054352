import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import shortid from 'shortid';
import { currencyFormat } from '../../constants';
import { countries } from 'country-data';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class SalesByCountryTable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: this.props.data,
      page: 0,
      rowsPerPage: 10,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.data !== nextProps.data) {
      return this.setState({ data: nextProps.data });
    }
  }

  render() {
    const { classes } = this.props;
    const { data, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                return (
                  <TableRow key={shortid.generate()}>
                    <TableCell>{countries[n[0]].name}</TableCell>
                    <TableCell>{n[1].quantity}</TableCell>
                    <TableCell>{currencyFormat.format(n[1].total)}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={3}/>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

SalesByCountryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(SalesByCountryTable);
