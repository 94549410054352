import React from 'react';
import ReBarChart from './charts/reBar';

const PartsQuotedBarChart = ({
  partsQuotedAll,
  animationDuration,
  isPresentation,
  subHeading,
}) => {
  if (!partsQuotedAll) return '';
  const data = {
    labels: partsQuotedAll.slice(0, 8).map(part => part.partNumber),
    data: partsQuotedAll.slice(0, 8).map(part => part.count),
  };
  return (
    <div>
      <ReBarChart
        isPresentation={isPresentation}
        animationDuration={animationDuration}
        chartData={data}
        heading="Top 8 Parts Quoted"
        subHeading={subHeading}
      />
    </div>
  );
};

export default PartsQuotedBarChart;
