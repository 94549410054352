import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import blue from '@material-ui/core/colors/blue';
import { pwnedPassword } from 'hibp';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { createUser, updateUser } from '../../actions/UserActions';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  submitProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  passwordInput: {
    width: '50%',
  },
};

const initialState = {
  username: '',
  newPassword: '',
  newPasswordConfirmation: '',
  role: '',
  submitted: false,
  error: null,
  passwordFound: 0,
  deleteUser: null,
  formState: null,
};

class EditAccountDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleClose = () => {
    this.setState(initialState);
    this.props.onClose(this.props.selectedValue);
  };

  onChange = e => {
    this.setState({ error: null });
    const newValue = e.target.value;
    const field = e.target.dataset.field;
    this.setState({ [field]: newValue });
    if (field === 'newPassword' && newValue.length) {
      pwnedPassword(newValue)
        .then(data => {
          if (data) {
            this.setState({ passwordFound: data });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (field === 'newPassword' && newValue.length === 0) {
      this.setState({ passwordFound: 0 });
    }
  };

  roleChange = e => {
    this.setState({ role: e.target.value });
  };

  isValid = () => {
    return true;
  };

  onSubmit = e => {
    if (
      this.state.newPassword !== '' &&
      this.state.newPassword !== this.state.newPasswordConfirmation
    ) {
      this.setState({ error: 'Passwords do not match' });
      return;
    }
    const data = {};
    data.id = this.props.user.id || null;

    if (this.state.username) data.username = this.state.username;
    if (this.state.newPassword) data.password = this.state.newPassword;
    if (this.state.role) data.role = this.state.role;

    this.setState({ submitted: true });
    if (data.id == null) {
      this.props.createuser(data);
    } else {
      this.props.updateuser(data);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userupdatestatus === this.props.userupdatestatus) return;
    if (this.props.userupdatestatus === 0) {
      this.setState({ submitted: false });
      this.handleClose();
    }
    if (this.props.userupdatestatus === 1) {
      this.setState({ submitted: true, error: null });
    }
    if (this.props.userupdatestatus === 3) {
      this.setState({ submitted: false, error: 'Could not update user' });
    }
    if (this.props.userupdatestatus === 2) {
      this.setState({ submitted: false, error: 'Could not create user' });
    }
  }
  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    const user = this.props.user ? this.props.user : {};
    const roles = this.props.roles || [];
    if (this.props.user === null) return <div />;

    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          {...other}
        >
          <DialogTitle id="simple-dialog-title">
            {this.isCreatingUser() ? 'Add new user' : 'Edit Account'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText gutterBottom={true}>
              <AccountCircle style={{ verticalAlign: 'bottom' }} />{' '}
              {this.isCreatingUser() ? <em>New User</em> : user.username}
            </DialogContentText>
            {this.state.error && <p>{this.state.error}</p>}
            {this.isCreatingUser() && (
              <>
                <br />
                <TextField
                  error={!this.state.username.toLowerCase().match(/a-z0-9/)}
                  value={this.state.username}
                  onChange={this.onChange}
                  inputProps={{ 'data-field': 'username' }}
                  label="Username"
                  fullWidth={true}
                />
                <br />
              </>
            )}
            {roles.length && (
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  placeholder="Role"
                  value={this.state.role || this.props.user.role}
                  onChange={this.roleChange}
                >
                  {roles.map(role => (
                    <MenuItem value={role} key={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              type="password"
              value={this.state.newPassword}
              onChange={this.onChange.bind(this)}
              inputProps={{ 'data-field': 'newPassword' }}
              label="New Password"
              className={classes.passwordInput}
            />
            <TextField
              margin="normal"
              type="password"
              value={this.state.newPasswordConfirmation}
              onChange={this.onChange}
              inputProps={{ 'data-field': 'newPasswordConfirmation' }}
              required={this.state.newPassword !== ''}
              disabled={this.state.newPassword === ''}
              error={
                this.state.newPassword !== '' &&
                this.state.newPasswordConfirmation !== this.state.newPassword
              }
              label="Repeat"
              className={classes.passwordInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.onSubmit.bind(this)}
              color="primary"
              autoFocus
              disabled={this.state.error}
            >
              OK
            </Button>
            {this.state.submitted && (
              <CircularProgress size={24} className={classes.submitProgress} />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  isCreatingUser() {
    return !this.props.user.id;
  }

  deleteUser() {
    this.setState({ deleteUser: this.props.user });
  }
}

EditAccountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  refresh: PropTypes.func,
};

const mapStateToProps = store => {
  return {
    currentuser: store.user.user,
    roles: store.admin.roles,
    errors: store.admin.error,
    userupdatestatus: store.admin.userUpdateStatus,
  };
};

const mapDispatchToProps = {
  createuser: user => createUser(user),
  updateuser: user => updateUser(user),
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditAccountDialog));
