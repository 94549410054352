import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../Loader';
import Typography from '@material-ui/core/Typography';
import { resultCard } from '../utils/card';
import SalesCustomersTable from '../tables/SalesCustomersTable';
import SalesCustomersDonut from '../SalesCustomersDonut';

class Customers extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const { salesCustomers, loading } = this.props;
    if (!salesCustomers.results.length) {
      return <Loader />;
    }

    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Customers
        </Typography>
        <Grid container spacing={24} justify="flex-start" direction="row">
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent style={{ height: '100%' }}>
                <SalesCustomersDonut
                  salesCustomers={salesCustomers.results.slice(0, 5)}
                  key="0"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            {resultCard('Sales by Customer', salesCustomers, SalesCustomersTable)}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'customers' }),
  };
};

export default connect(
  store => {
    return {
      salesCustomers: {
        results: store.sales.customers,
        error: store.sales.customersError,
        loading: store.sales.customersFetching,
      },
    };
  },
  mapDispatchToProps
)(Customers);
