import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SalesLine from '../SalesLine';
import StaffSalesLine from '../StaffSalesLine';
import SummaryCard from '../SummaryCard';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Link, withRouter } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PartsQuotedBarChart from '../PartsQuotedBarChart';
import PartsNotQuotedBarChart from '../PartsNotQuotedBarChart';
import PartsEnquiredBarChart from '../PartsEnquiredBarChart';
import Fader from 'react-fader';
import QuotesLine from '../QuotesLine';
import {
  fetchSales,
  fetchPartsEnquired,
  fetchStaffSales,
  fetchStaffSalesSummary,
  fetchOrders,
  getPartsQuoted,
  getPartsNotQuoted,
  fetchValuations,
  fetchQuotations,
  fetchQuotationsSummary,
  fetchRecentQuotations,
  fetchOrdersByStoreman,
  fetchOrdersBySupplier,
  fetchOrderBalance,
  fetchPartsNotQuoted,
  fetchCountrySales,
} from '../../actions/InitActions';
import moment from 'moment';
import OrderBookDonut from '../OrderBookDonut';
import SalesMap from '../SalesMap';
import { logout } from '../../actions/UserActions';
import Loader from '../Loader';
import logo from '../../assets/perkins_logo_blue@2x.png';
import HaywardGreenLogo from '../../assets/H&G_logo_Linear_RGB.png';
import PresentationOrderBook from '../PresentationOrderBook';
import SalesByCountry from '../SalesByCountry';

const styles = {
  container: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.04)',
    position: 'relative',
    '& .recharts-legend-item-text': {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  buttonWrap: {
    width: '100%',
    display: 'flex',
    // padding: '30px'
    '& > img': {
      width: '80px',
    },
    '& > div > div': {
      textAlign: 'center !important',
      '& div': {
        textAlign: 'left',
      },
    },
    '& h1': {
      fontSize: '2rem',
    },
    justifyContent: 'center',
    paddingTop: '4vh',
    '& > div': {
      boxShadow: 'none',
      maxWidth: '350px',
    },
  },
  slidesContainer: {
    width: '100%',
    height: '100%',
    '& h1': {
      fontWeight: 'bold',
      // fontSize: '2rem'
    },
  },
  slideInner: {
    padding: '6vh 8vw',
    '& > div > div > h1': {
      marginBottom: '0.75em',
    },
  },
  sideInfo: {
    width: '300px',
  },
  closeButton: {
    position: 'absolute',
    right: '30px',
    top: '30px',
    zIndex: 99999,
    transform: 'scale(1.5)',
  },
  prev: {
    position: 'absolute',
    left: '30px',
    top: '50%',
    transform: 'translateY(-50%) scale(1.5)',
  },
  next: {
    position: 'absolute',
    right: '30px',
    top: '50%',
    transform: 'translateY(-50%) scale(1.5)',
  },
  donutSlide: {
    '& .recharts-legend-item': {
      margin: '20px 0',
      fontWeight: '700',
    },
    '& .recharts-legend-item-text': {
      marginLeft: '22px',
      fontSize: '28px',
    },
    '& .recharts-legend-wrapper': {
      marginRight: '12vw',
    },
    '& .pie-label-presentation': {
      fontSize: '2vw',
      fontWeight: 700,
    },
  },
};

class Presentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      currentSlide: 0,
      totalSlides: 10,
      isLoadingData: true,
      hasRequestedData: false
    };
  }

  readyToRender(props, state) {
    return state.hasRequestedData
      && !props.isLoadingStoremen
      && !props.isLoadingPartsEnquiredAll
      && !props.isLoadingPartsQuoted
      && !props.isLoadingPartsNotQuoted
      && !props.isLoadingSales
      && !props.isLoadingStaffSales
      && !props.isLoadingQuotations;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.readyToRender(nextProps, nextState);
  }

  niceDate() {
    return moment();
  }

  logoutAction = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  nextSlide() {
    if (this.state.currentSlide >= this.state.totalSlides - 1) {
      this.setState({
        currentSlide: 0,
      });
    } else {
      this.setState({
        currentSlide: this.state.currentSlide + 1,
      });
    }
  }
  prevSlide() {
    if (this.state.currentSlide <= 0) {
      this.setState({
        currentSlide: this.state.totalSlides - 1,
      });
    } else {
      this.setState({
        currentSlide: this.state.currentSlide - 1,
      });
    }
  }

  componentDidMount() {
    const salesTimeSpan = {
      start_date: this.niceDate()
        .subtract(20, 'days')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
    };

    const staffSalesSummaryTimeSpan = {
      start_date: this.niceDate()
        .subtract(180, 'days')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
    };

    const partsTimeSpan = {
      start_date: this.niceDate()
        .subtract(180, 'days')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
    };

    const quotationsTimeSpan = {
      start_date: this.niceDate()
        .subtract(180, 'days')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'week',
    };

    const countrySalesTimeSpan = {
      start_date: this.niceDate()
        .subtract(180, 'days')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
      order_by: 'total desc'
    };

    const pastDatedueTimeSpan = {
      start_datedue: this.niceDate()
        .subtract(30, 'years')
        .format('YYYY-MM-DD'),
      end_datedue: this.niceDate()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
      subtype: 'PAST_DATEDUE',
      order_by: 'datedue asc'
    };

    const futureDatedueTimeSpan = {
      start_datedue: this.niceDate()
        // .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      end_datedue: this.niceDate()
        .add(7, 'days')
        .format('YYYY-MM-DD'),
      cohort: 'day',
      subtype: 'FUTURE_DATEDUE',
    };

    this.props.updateSalesData(salesTimeSpan);
    this.props.updatePartsEnquired(partsTimeSpan);
    this.props.updatePartsQuoted(partsTimeSpan);
    this.props.updatePartsNotQuoted(partsTimeSpan);
    this.props.updateOrders(salesTimeSpan);
    this.props.updateOrders(pastDatedueTimeSpan);
    this.props.updateOrders(futureDatedueTimeSpan);
    this.props.updateStaffSalesSummary(staffSalesSummaryTimeSpan);
    this.props.updateOrdersByStoreman(partsTimeSpan);
    this.props.updateQuotes(quotationsTimeSpan);
    this.props.updateCountrySales(countrySalesTimeSpan);

    this.interval = setInterval(() => {
      this.nextSlide();
    }, 30000);

    this.setState({
      hasRequestedData: true,
    });
  }
  componentWillUnmount() {
    const initialTimeSpan = {
      start_date: this.niceDate()
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      end_date: this.niceDate().format('YYYY-MM-DD'),
      cohort: 'day',
    };
    this.props.resetData(initialTimeSpan);
    clearInterval(this.interval);
    this.setState({ isLoadingData: true });
  }

  formatPartsQuoted(partsQuoted) {
    return partsQuoted.map(val => {
      ['minimum', 'maximum'].forEach(e => delete val[e]);
      return val;
    });
  }

  formatPartsNotQuoted(partsNotQuoted) {
    return partsNotQuoted.map(val => {
      ['minimum', 'maximum'].forEach(e => delete val[e]);
      return val;
    });
  }

  render() {
    const {
      classes,
      sales,
      staffSales,
      staffSalesTotals,
      partsQuoted,
      partsNotQuoted,
      partsEnquired,
      orders,
      quotes,
      countrySales,
    } = this.props;

    if (!this.readyToRender(this.props, this.state)) {
      return (
        <div className={[classes.container, 'fullscreen-summary'].join(' ')}>
          {this.props.user.user && this.props.user.user.role === 'presentation'
            ?
            <Link className={classes.closeButton} onClick={this.logoutAction} to="/">
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Link>
            :
            <Link className={classes.closeButton} to="/">
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Link>
          }
          <h1>Loading data, please wait.</h1>
          <p>This could take up to 30 seconds or more depending on the amount of data and current network conditions.</p>
          <Loader />
          <img src={logo} alt="Perkins Reflects" />
        </div>
      );
    }

    const SalesSlide = (
      <div className={classes.slideInner}>
        <SalesLine
          isPresentation={true}
          animationDuration={2000}
          hideToggle={true}
          dataset={sales}
          subHeading="Past 20 days"
        />
        <div className={classes.buttonWrap}>
          <SummaryCard data={sales} type="total" text="Sales" icon="sales" />
          <SummaryCard data={sales} type="orders" text="Orders" icon="order" />
        </div>
      </div>
    );

    const StaffSalesSlide = (
      <div className={classes.slideInner}>
        <StaffSalesLine
          isPresentation={true}
          animationDuration={2000}
          hideToggle={true}
          dataset={staffSales}
          subHeading="Past 20 days"
        />
        <div className={classes.buttonWrap}>
          <SummaryCard data={sales} type="total" text="Sales" icon="sales" />
          <SummaryCard data={sales} type="orders" text="Orders" icon="order" />
        </div>
      </div>
    );

    const PartQuotedSlide = (
      <div className={classes.slideInner}>
        <PartsQuotedBarChart
          partsQuotedAll={partsQuoted}
          isPresentation={true}
          animationDuration={2000}
          subHeading="Past 180 days"
        />
      </div>
    );

    console.log(partsNotQuoted);
    const PartNotQuotedSlide = (
      <div className={classes.slideInner}>
        <PartsNotQuotedBarChart
          partsNotQuotedAll={partsNotQuoted}
          isPresentation={true}
          animationDuration={2000}
          subHeading="Past 180 days"
        />
      </div>
    );

    const PartEnquiredSlide = (
      <div className={classes.slideInner}>
        <PartsEnquiredBarChart
          partsEnquiredAll={partsEnquired}
          isPresentation={true}
          animationDuration={2000}
          subHeading="Past 180 days"
        />
      </div>
    );

    const OrderBookDonutSlide = (
      <div className={[classes.slideInner, classes.donutSlide].join(' ')}>
        <OrderBookDonut
          isPresentation={true}
          animationDuration={2000}
          subHeading="Past 180 days"
          orders={staffSalesTotals}
        />
      </div>
    );

    const QuotesTotalSlide = (
      <div className={classes.slideInner}>
        <QuotesLine
          dataset={quotes}
          isPresentation={true}
          animationDuration={2000}
          subHeading="Past 180 days"
        />
        <div className={classes.buttonWrap}>
          <SummaryCard
            data={quotes}
            type="sale_price"
            text="Total"
            icon="sales"
          />
        </div>
      </div>
    );

    const MapSlide = (
      <div className={classes.slideInner}>
        <h1 class="MuiTypography-root-157 MuiTypography-display2-160 MuiTypography-alignCenter-170" style={{ marginBottom: '0.5em', marginTop: '-20px' }}>Sales by country</h1>
        <h1 class="MuiTypography-root-157 MuiTypography-display1-161 MuiTypography-alignCenter-170">Past 180 days</h1>
        <SalesMap usePresentationStyle title="&nbsp;" />
      </div>
    );

    const SalesByCountrySlide = (
      <div className={classes.slideInner}>
        <SalesByCountry
          countrySales={countrySales}
          heading="10 countries with highest sales"
          subHeading="Past 180 days"
        />
      </div>
    );

    const OrdersUpcomingSlide = (
      <div className={classes.slideInner}>
        <PresentationOrderBook
          orders={orders.futureDatedue.results}
          heading="Orders Upcoming"
          subHeading="Next 7 days"
          color="green"
        />
      </div>
    );

    const OrdersOverdueSlide = (
      <div className={classes.slideInner}>
        <PresentationOrderBook
          orders={orders.pastDatedue.results}
          heading="Orders Overdue"
          subHeading="All time"
          color="red"
        />
      </div>
    );

    const Slides = [
      SalesSlide,
      StaffSalesSlide,
      PartQuotedSlide,
      PartNotQuotedSlide,
      PartEnquiredSlide,
      OrderBookDonutSlide,
      QuotesTotalSlide,
      MapSlide,
      SalesByCountrySlide,
      OrdersUpcomingSlide,
      OrdersOverdueSlide,
    ];

    return (
      <div className={[classes.container, 'fullscreen-summary'].join(' ')}>
        {this.props.user.user && this.props.user.user.role === 'presentation'
          ?
          <Link className={classes.closeButton} onClick={this.logoutAction} to="/">
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Link>
          :
          <Link className={classes.closeButton} to="/">
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Link>
        }
        <div className={classes.slidesContainer}>
          <img src={HaywardGreenLogo} className={'hayward-green-logo'} />
          <Fader fadeOutTransitionDuration={500} fadeInTransitionDuration={500}>
            {Slides[this.state.currentSlide]}
          </Fader>
        </div>
        <IconButton
          onClick={() => {
            this.prevSlide();
            clearInterval(this.interval);
          }}
          className={classes.prev}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            this.nextSlide();
            clearInterval(this.interval);
          }}
          className={classes.next}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSalesData: args => {
      dispatch(fetchSales(args));
      dispatch(fetchStaffSales(args));
    },
    updatePartsEnquired: args => {
      dispatch(fetchPartsEnquired(args, 'all'));
    },
    updatePartsQuoted: args => {
      dispatch(getPartsQuoted(args));
    },
    updatePartsNotQuoted: args => {
      dispatch(getPartsNotQuoted(args));
    },
    updateOrders: args => {
      dispatch(fetchOrders(args));
    },
    updateOrdersByStoreman: args => {
      dispatch(fetchOrdersByStoreman(args));
    },
    updateStaffSalesSummary: args => {
      dispatch(fetchStaffSalesSummary(args));
    },
    updateQuotes: args => {
      dispatch(fetchQuotationsSummary(args));
    },
    updateCountrySales: args => {
      dispatch(fetchCountrySales(args));
    },
    resetData: args => {
      dispatch(fetchSales(args));
      dispatch(fetchPartsNotQuoted(args));
      dispatch(fetchPartsEnquired(args, 'all'));
      dispatch(fetchStaffSales(args));
      dispatch(fetchStaffSalesSummary(args));
      dispatch(fetchOrders(args));
      dispatch(getPartsQuoted(args));
      dispatch(getPartsNotQuoted(args));
      dispatch(fetchValuations(args));
      dispatch(fetchQuotations(args));
      dispatch(fetchQuotationsSummary(args));
      dispatch(fetchRecentQuotations(args));
      dispatch(fetchOrdersByStoreman(args));
      dispatch(fetchOrdersBySupplier(args));
      dispatch(fetchOrderBalance(args));
      dispatch(fetchCountrySales(args));
    },
    logout: () => dispatch(logout()),
  };
};

export default connect(
  store => {
    return {
      sales: store.sales.all,
      staffSales: store.sales.staff,
      countrySales: store.sales.countrySales,
      partsEnquired: store.parts.partsEnquired,
      partsQuoted: store.parts.partsQuoted,
      partsNotQuoted: store.parts.partsNotQuoted,
      orders: store.orders,
      staffSalesTotals: store.sales.summary,
      quotes: store.quotations.total,
      user: store.user,
      isLoadingStoremen: store.orders.storeman.loading,
      isLoadingPartsEnquiredAll: store.partsEnquiredAllLoading,
      isLoadingPartsQuoted: store.parts.partsQuotedLoading,
      isLoadingPartsNotQuoted: store.parts.partsNotQuotedLoading,
      isLoadingSales: store.sales.allFetching,
      isLoadingStaffSales: store.sales.staffFetching,
      isLoadingQuotations: store.quotations.loading
    };
  },
  mapDispatchToProps
)(withRouter(withStyles(styles)(Presentation)));
