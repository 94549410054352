import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import { card } from './card';
import GenericError from '../GenericError';

class ResultCard extends React.Component {
  render() {
    const { results, error, loading } = this.props.context;
    const { title, cardProps, contentProps, resultsProps } = this.props;
    const ComposedComponent = this.props.component;
    if (loading) {
      return card(<Loader />, cardProps, contentProps);
    }
    if (error) {
      return card(
        <GenericError title={title}>
          An error has occurred while loading this report.
        </GenericError>,
        cardProps,
        contentProps
      );
    }
    if (results && results.length === 0) {
      return card(<GenericError title={title} />, cardProps);
    }
    if (!results) return <></>;
    return card(
      <ComposedComponent
        title={title}
        data={results}
        {...resultsProps || {}}
      />,
      cardProps,
      contentProps
    );
  }
}

ResultCard.propTypes = {
  title: PropTypes.string,
  context: PropTypes.object,
  component: PropTypes.func,
  cardProps: PropTypes.object,
  contentProps: PropTypes.object,
  resultsProps: PropTypes.object,
};

export default ResultCard;
