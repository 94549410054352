import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

class ReleaseNotes extends React.Component {

  render() {
    return (
      <div align="left">
        <Typography align="left" variant="title" gutterBottom={true}>
          Release Notes
        </Typography>
        <br />

        Version 1.2.0 - 17/01/2020
        <ul>
          <li>Added a Customers section for managers to see which customers are requesting / purchasing most items.</li>
        </ul>
        <br />

        Version 1.1.0 - 07/10/2019
        <ul>
          <li>Ability for management users to see additional gross profit information.</li>
        </ul>
        <br />

        Version 1.0.0
        <ul>
          <li>Ability to filter all reports by custom date range, and report data on daily, weekly or monthly basis.</li>
          <li>Dashboard. Current reports on display:
            <ul>
              <li>Total sales</li>
              <li>Sales by country</li>
              <li>Top 5 parts enquired</li>
              <li>Sales totals by date</li>
              <li>Parts enquired</li>
              <li>Parts quoted</li>
              <li>Staff sales</li>
              <li>Staff sales totals by staff member</li>
            </ul>
          </li>
          <li>Sales page. Current reports on display:
            <ul>
              <li>Total sales</li>
              <li>Gross profit</li>
              <li>Number of orders</li>
              <li>Sales totals by date</li>
              <li>Sales by sector</li>
              <li>Sales by aircraft</li>
              <li>Sales by aircraft type</li>
            </ul>
          </li>
          <li>A staff page available to management users, with data broken down by staff member.</li>
          <li>Quotations page shows the value of quotes sent out.</li>
          <li>Order book shows orders due to be delivered, and orders overdue.</li>
          <li>Stock page shows the value of stock held.</li>
          <li>Parts page displays most commonly requested parts.</li>
          <li>Presentation slide show suitable for a large screen. Current reports on display:
            <ul>
              <li>Total sales past 20 days</li>
              <li>Staff sales past 20 days</li>
              <li>Top 8 parts quoted past 180 days</li>
              <li>Top 8 parts enquired past 180 days</li>
              <li>Total orders by sales people past 180 days</li>
              <li>Quotations total past 180 days</li>
              <li>Sales by country past 180 days</li>
              <li>10 countries with highest sales past 180 days</li>
              <li>Orders upcoming next 7 days</li>
              <li>Orders overdue all time</li>
            </ul>
          </li>
          <li>Ability to edit account.</li>
          <li>User management for admin users.</li>
          <li>Compatible with the latest version of Chrome, Firefox and Edge at the time of release.</li>
        </ul>
      </div>
    );
  }
}

export default connect(
)(ReleaseNotes);
