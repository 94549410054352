const defaultState = {
  all: [],
  staff: {},
  staffProfits: {},
  staffProfitsSummary: {},
  summary: [],
  sector: [],
  detail: [],
  allFetching: false,
  staffFetching: false,
  staffProfitsFetching: false,
  staffProfitsSummaryFetching: false,
  summaryFetching: false,
  detailFetching: false,
  allError: false,
  summaryError: false,
  sectorError: false,
  staffError: false,
  staffProfitsError: false,
  staffProfitsSummaryError: false,
  detailError: false,
  aircraft: [],
  aircraftFetching: false,
  aircraftError: false,
  countrySales: {},
  countrySalesFetching: false,
  countrySalesError: null,
  customers: [],
  customersFetching: false,
  customersError: false,
};

export default function sales(state = defaultState, action) {
  switch (action.type) {
    case 'SALES_FETCHING':
      return { ...state, allFetching: true };
    case 'SALES_FULFILLED':
      return { ...state, allFetching: false, all: action.payload };
    case 'SALES_ERROR':
      return { ...state, allFetching: false, allError: action.payload };
    case 'SALES_AIRCRAFT_FETCHING':
      return { ...state, aircraftFetching: true };
    case 'SALES_AIRCRAFT_FULFILLED':
      return { ...state, aircraftFetching: false, aircraft: action.payload };
    case 'SALES_AIRCRAFT_ERROR':
      return {
        ...state,
        aircraftFetching: false,
        aircraftError: action.payload,
      };
    case 'SALES_CUSTOMERS_FETCHING':
      return { ...state, customersFetching: true };
    case 'SALES_CUSTOMERS_FULFILLED':
      return { ...state, customersFetching: false, customers: action.payload };
    case 'SALES_CUSTOMERS_ERROR':
      return {
        ...state,
        customersFetching: false,
        customersError: action.payload,
      };
    case 'STAFF_SALES_FETCHING':
      return { ...state, staffFetching: true };
    case 'STAFF_SALES_FULFILLED':
      return { ...state, staffFetching: false, staff: action.payload };
    case 'STAFF_SALES_ERROR':
      return { ...state, staffFetching: false, staffError: action.payload };
    case 'STAFF_PROFITS_FETCHING':
      return { ...state, staffProfitsFetching: true };
    case 'STAFF_PROFITS_FULFILLED':
      return { ...state, staffProfitsFetching: false, staffProfits: action.payload };
    case 'STAFF_PROFITS_ERROR':
      return { ...state, staffProfitsFetching: false, staffProfitsError: action.payload };
    case 'STAFF_PROFITS_SUMMARY_FETCHING':
      return { ...state, staffProfitsSummaryFetching: true };
    case 'STAFF_PROFITS_SUMMARY_FULFILLED':
      return { ...state, staffProfitsSummaryFetching: false, staffProfitsSummary: action.payload };
    case 'STAFF_PROFITS_SUMMARY_ERROR':
      return { ...state, staffProfitsSummaryFetching: false, staffProfitsSummaryError: action.payload };
    case 'STAFF_SALES_SUMMARY_FETCHING':
      return { ...state, summaryFetching: true };
    case 'STAFF_SALES_SUMMARY_FULFILLED':
      return { ...state, summaryFetching: false, summary: action.payload };
    case 'STAFF_SALES_SUMMARY_ERROR':
      return { ...state, summaryFetching: false, summaryError: action.payload };
    case 'COUNTRY_SALES_FETCHING':
      return {
        ...state,
        countrySales: {},
        countrySalesFetching: true,
        countrySalesError: null,
      };
    case 'COUNTRY_SALES_FULFILLED':
      return {
        ...state,
        countrySales: action.payload,
        countrySalesFetching: false,
        countrySalesError: null,
      };
    case 'COUNTRY_SALES_ERROR':
      return {
        ...state,
        countrySales: [],
        countrySalesFetching: false,
        countrySalesError: action.payload,
      };
    case 'SECTOR_SALES_FETCHING':
      return { ...state, sectorFetching: true };
    case 'SECTOR_SALES_FULFILLED':
      return { ...state, sectorFetching: false, sector: action.payload };
    case 'SECTOR_SALES_ERROR':
      return { ...state, sectorFetching: false, sectorError: action.payload };
    case 'SALES_DETAIL_FETCHING':
      return { ...state, detailFetching: true };
    case 'SALES_DETAIL_FULFILLED':
      return { ...state, detailFetching: false, detail: action.payload };
    case 'SALES_DETAIL_ERROR':
      return { ...state, detailFetching: false, detailError: action.payload };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
