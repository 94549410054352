import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { longIntFormat } from '../constants';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import SalesIcon from '../assets/icon_sales_2.svg';
import OrderIcon from '../assets/icon_orders.svg';
import ProfitIcon from '../assets/icon_profit.svg';
import StockIcon from '../assets/icon_stock_valuation.svg';
import { getTotal } from '../utils/getTotal';

//region Constants
const styles = {
  icon: {
    width: '60px',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '20px',
  },
  wrap: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  heading: {
    margin: '0 !important',
    fontWeight: 'bold',
  },
};

const icons = {
  sales: SalesIcon,
  order: OrderIcon,
  stock: StockIcon,
  profit: ProfitIcon,
};
//endregion

class SummaryCard extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      animDone: false,
    };
  }
  componentDidMount() {
    const total = getTotal(this.props.data, this.props.type);

    this.setState({ value: total, animDone: true });
  }

  render() {
    const { data, type, classes, text, icon } = this.props;
    const format = this.props.format || longIntFormat;
    const total = getTotal(data, type);
    if (!data.length && typeof data === 'object') {
      return (
        <Card>
          <CardContent>No data available</CardContent>
        </Card>
      );
    } else {
      return (
        <Card>
          <CardContent style={{ textAlign: 'left' }}>
            <img alt="" className={classes.icon} src={icons[icon]} />
            <div className={classes.wrap}>
              <Typography variant="display1" className={classes.heading}>
                {!this.state.animDone && format.format(this.state.value)}
                {this.state.animDone && format.format(total)}
              </Typography>
              <Typography variant="headline" align="left">
                {text}
              </Typography>
            </div>
          </CardContent>
        </Card>
      );
    }
  }
}

export default withStyles(styles)(SummaryCard);
