/* global d3 */

import React from 'react';
import Datamap from '../components/utils/datamap';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { currencyFormat } from '../constants';
import objectValues from 'object.values';

// const colors = d3.scale.category10();

class SalesMap extends React.Component {
  render() {
    let data = this.props.dataset;
    sanitiseData(data);
    setColors(data);

    return (
      <div
        label="Choropleth"
        className={this.props.usePresentationStyle ? "Presentation Map ResponsiveMap" : "Map ResponsiveMap"}
        style={this.props.usePresentationStyle ? PresentationStyle : {}}
      >
        {!this.props.usePresentationStyle && (<Typography align="left" variant="headline" gutterBottom>
          {this.props.title || 'Sales by Country'}
        </Typography>)}
        {this.props.usePresentationStyle && (<div style={{ height: "30%" }}></div>)}
        <Datamap
          data={data}
          fills={{
            defaultFill: '#EFEFEF',
          }}
          projection="mercator"
          updateChoroplethOptions={{ reset: false }}
          responsive
          geographyConfig={{
            popupTemplate: (geo, data) =>
              `<div class="hoverinfo"><h5>${geo.properties.name}</h5>
              ${currencyFormat.format(data.total)} (${
                data.count
              } orders)</div>`,
            highlightFillColor: '#029DAF',
            highlightBorderColor: 'rgba(3, 209, 233, 0.2)',
          }}
        />
      </div>
    );
  }
}

const PresentationStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-425px',
  marginLeft: '-637px'
};

const mapStateToProps = store => {
  return { dataset: store.sales.countrySales };
};

if (!Object.values) {
  objectValues.shim();
}

function sanitiseData(data) {
  Object.keys(data).forEach(function(key) {
    if (data[key].total === 0) {
      delete data[key];
    }
  });
}
function setColors(data) {
  const keys = Object.values(data);
  const onlyValues = keys.map(function(obj) {
    return obj.total;
  });

  const minValue = 1,
    maxValue = Math.max.apply(null, onlyValues);
  const paletteScale = d3.scale
    .log()
    .domain([minValue, maxValue])
    .range(['#e0e4f0', '#2b3557']); // lightColor, darkColor

  Object.keys(data).forEach(function(key) {
    data[key].fillColor = paletteScale(data[key].total);
  });
}

export default connect(mapStateToProps)(SalesMap);
