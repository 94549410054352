import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import shortid from 'shortid';
import { currencyFormat } from '../../constants';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const Simple = ({ data, heading, classes, currencyColumn, columnCount = 3 }) => {
  const thead = Object.keys(data[0]);
  return (
    <div>
      <Typography
        align="left"
        variant="headline"
        children={heading}
        gutterBottom
      />
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {thead.map(k => (
                <TableCell key={shortid.generate()}>{k}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={shortid.generate()}>
                  <TableCell component="th" scope="row">
                    {currencyColumn === 0
                      ? currencyFormat.format(n[thead[0]])
                      : n[thead[0]]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {currencyColumn === 1
                      ? currencyFormat.format(n[thead[1]])
                      : n[thead[1]]}
                  </TableCell>
                  {columnCount === 3 ?
                    <TableCell component="th" scope="row">
                      {currencyColumn === 2
                        ? currencyFormat.format(n[thead[2]])
                        : n[thead[2]]}
                    </TableCell> : ''
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

Simple.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Simple);
