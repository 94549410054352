import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fetchUsers } from '../../actions/InitActions';

const styles = () => ({
  mr_10: {
    marginRight: '10px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class UserList extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.users.map(user => (
            <TableRow key={user.id}>
              <TableCell scope="row">{user.username}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell align="right">
                <div className={classes.actions}>
                  <Button
                    className={classes.mr_10}
                    variant="contained"
                    color="secondary"
                    onClick={_ => this.props.onSelect(user)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={_ => this.props.onDelete(user)}
                  >
                    Delete
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = store => {
  return {
    users: store.admin.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userState: {
      refresh: () => dispatch(fetchUsers()),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserList));
