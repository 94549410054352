import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableSimple from './tables/Simple';

const StaffSalesTotals = ({ data }) => {
  if (!data.length) {
    return (
      <Paper>
        <Typography variant="headline" gutterBottom>
          Staff Sales Totals
        </Typography>
        <Typography variant="button">No data available</Typography>
      </Paper>
    );
  }
  return (
    <TableSimple
      data={data}
      currencyColumn={1}
      heading="Summary/Totals"
      thead="foo"
    />
  );
};

export default StaffSalesTotals;
