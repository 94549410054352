import React from 'react';
import { withRouter } from 'react-router-dom';
import ReDoughnutChart from './charts/reDoughnut';
import { currencyFormat } from '../constants';

const SectorSalesDonut = ({ sectorSales, link, title }) => {
  if (!Object.keys(sectorSales).length) {
    return <p>No Data found</p>;
  }
  const data = {
    labels: Object.keys(sectorSales),
    data: Object.keys(sectorSales).map(sector => sectorSales[sector]),
  };

  return (
    <div>
      <ReDoughnutChart
        currencySymbol="$"
        isCurrency={true}
        linkTo={'/staff'}
        centerText="Total sales"
        chartData={data}
        headline="Sales by sector"
        tooltipFormatter={currencyFormat}
      />
    </div>
  );
};

export default withRouter(SectorSalesDonut);
