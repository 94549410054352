import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import QuotesLine from '../QuotesLine';
import Loader from '../Loader';
import RecentQuotationsTable from '../tables/RecentQuotationsTable';
import Typography from '@material-ui/core/Typography';
import SummaryCard from '../SummaryCard';
import GenericError from '../GenericError';

class Quotes extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const { quotes, recentQuotations, status } = this.props;
    if (!quotes.length && status === 'FETCHING') {
      return <Loader />;
    } else if (!quotes.length && status === 'FULFILLED') {
      return (
        <div>
          <Typography variant="display1" align="left" gutterBottom>
            Quotations
          </Typography>
          <GenericError />
        </div>
      );
    }
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Quotations
        </Typography>
        <Grid container spacing={24} justify="flex-start" direction="row">
          <Grid item xs={12} md={6} lg={4}>
            <SummaryCard
              data={quotes}
              type="sale_price"
              text="Total"
              icon="sales"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <QuotesLine dataset={quotes} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <RecentQuotationsTable data={recentQuotations} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'quotations' }),
  };
};

export default connect(
  store => {
    const v = {
      status: store.quotations.status,
      quotes: store.quotations.total,
      recentQuotations: store.quotations.recent,
    };
    return v;
  },
  mapDispatchToProps
)(Quotes);
