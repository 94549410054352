import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import OrdersBook from '../OrderBook';
import { fetchOrdersByStoreman } from '../../actions/InitActions';
import OrderBookSuppliers from '../tables/OrderBookSuppliers';
import Typography from '@material-ui/core/Typography';
import SummaryCard from '../SummaryCard';
import { resultCard } from '../utils/card';
import OrderBookStoreman from '../tables/OrderBookStoreman';
import { currencyFormat } from '../../constants';

class Orders extends React.Component {
  componentDidMount() {
    this.props.getSummary();
    this.props.open();
  }

  render() {
    const { all, storeman, supplier, balance } = this.props;
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Order Book
        </Typography>
        <Grid container spacing={24}>
          {!balance.loading && (
            <Grid item xs={12} md={6} lg={4}>
              <SummaryCard
                data={balance.value.total || {}}
                type="value"
                text="Total"
                icon="sales"
                format={currencyFormat}
              />
            </Grid>
          )}
          {!balance.loading && (
            <Grid item xs={12} md={6} lg={4}>
              <SummaryCard
                data={balance.value.count || {}}
                type="value"
                text="Outstanding orders"
                icon="sales"
              />
            </Grid>
          )}

          {resultCard('Orders by Supplier', supplier, OrderBookSuppliers, {
            xs: 12,
            sm: 12,
            md: 6,
          })}
          {resultCard('Orders by Storeman', storeman, OrderBookStoreman, {
            xs: 12,
            sm: 12,
            md: 6,
          })}
          <Grid item xs={12} sm={12}>
            <OrdersBook orders={all.results} title="Order Book" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSummary: () => dispatch(fetchOrdersByStoreman()),
    open: () => dispatch({ type: 'NAVIGATE', target: 'orders' }),
  };
};

export default connect(
  store => store.orders,
  mapDispatchToProps
)(Orders);
