export function scrubSales(rawData, cohort) {
  return rawData;
}

export function scrubStaffSales(rawData) {
  let staffSales = rawData.map((item, index) => {
    return {
      date: item[0],
      BP: item.BP.total,
    };
  });
  return staffSales.filter(item => item !== undefined);
}

export function scrubStaffSalesTotals(rawData) {
  let staffSalesTotals = rawData.map((item, index) => {
    return {
      storeman: item.storeman,
      total: item.total,
      orders: item.number_of_orders,
    };
  });

  staffSalesTotals.sort(function(a, b) {
    return parseInt(a.total, 10) < parseInt(b.total, 10) ? 1 : 0;
  });

  return staffSalesTotals.filter(item => item !== undefined);
}
