export const Unspecified = 0;
export const Narrow = 1;
export const Wide = 2;
export const Rotary = 4;
export const Military = 8;
export const Regional = 16;
export const BizJet = 32;

export const labels = {
  [Unspecified]: 'Other',
  [Narrow]: 'Narrow',
  [Wide]: 'Wide',
  [Rotary]: 'Rotary',
  [Military]: 'Military',
  [Regional]: 'Regional',
  [BizJet]: 'BizJet',
};
