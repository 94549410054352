import React from 'react';
import { Warning } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

class ImportPlaceholder extends React.Component {
  render() {
    const { title, children } = this.props;
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant={'headline'} align={'left'}>
          {title}
        </Typography>
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: '#575E7B',
            fontSize: '0.875rem',
            fontWeight: '400',
            fontFamily: 'Open Sans',
            lineHeight: '1.46429em',
          }}
        >
          <Warning
            nativeColor="#323D64"
            style={{ fontSize: '48px', marginTop: '1em' }}
          />
          <br />
          {children
            ? children
            : 'This data is currently being imported in to Perkins Reflects and is unavailable.'}
        </div>
      </div>
    );
  }
}

ImportPlaceholder.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ImportPlaceholder;
