import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PartsEnquiriesTable from './PartsEnquiresTable';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class PartsEnquired extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      includeQuoted: true,
    };
  }

  onQuotedSwitch(e) {
    this.setState({ includeQuoted: e.target.checked });
    this.props.onQuotedChange(e);
  }

  render() {
    if (!this.props.parts.length) {
      return (
        <Paper>
          <Typography align="left" variant="headline" gutterBottom>
            {this.props.title || 'Parts Enquired'}
          </Typography>
          <Typography variant="button">No data available</Typography>
        </Paper>
      );
    }
    return (
      <Card>
        <CardContent>
          <Grid container spacing={0} direction="row">
            <Grid item xs={6}>
              <Typography align="left" variant="headline" gutterBottom>
                {this.props.title || 'Parts Enquired'}
              </Typography>
            </Grid>
          </Grid>
          <PartsEnquiriesTable data={this.props.parts} />
        </CardContent>
      </Card>
    );
  }
}

export default PartsEnquired;
