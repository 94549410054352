import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import whiteLogo from '../assets/perkins_logo_white_with_hg@2x.png';
import caret from '../assets/caret.svg';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import FilterIcon from '../assets/FilterSlider.png';
import RefreshIcon from '../assets/refresh_white_36x36.png';
import CloseIcon from '@material-ui/icons/Close';
import { Link, withRouter } from 'react-router-dom';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import {
  fetchSales,
  fetchPartsEnquired,
  fetchStaffSales,
  fetchStaffProfits,
  fetchStaffProfitsSummary,
  fetchSectorSales,
  fetchStaffSalesSummary,
  fetchOrders,
  getPartsQuoted,
  fetchValuations,
  fetchQuotations,
  fetchQuotationsSummary,
  fetchRecentQuotations,
  fetchOrdersByStoreman,
  fetchOrdersBySupplier,
  fetchOrderBalance,
  fetchPartsNotQuoted,
  fetchCountrySales,
  fetchSalesByAircraft,
  fetchSalesByCustomers,
} from '../actions/InitActions';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MobileDateFilterDialog from './dialogs/MobileDateFilterDialog';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: '#029DAF !important',
    paddingRight: '0 !important',
  },
  toolbar: {
    minHeight: '1px !important',
  },
  displayFlex: {
    display: 'flex',
  },
  link: {
    height: '35px',
    '@media (max-width: 350px)': {
      height: '30px',
    },
    '& img': {
      height: '100%',
    },
    '@media (max-width: 959px)': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  alignCenter: {
    alignItems: 'center',
  },
  dateFilterForm: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
    color: 'white !important',
    // textTransform: 'uppercase',
    fontFamily: '"Roboto Slab"',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    '@media (max-width: 700px)': {
      marginRight: 0,
    },
  },
  selectMenuProps: {
    top: '38px !important',
  },
  button: {
    margin: theme.spacing.unit,
    color: 'white',
    marginLeft: '2em',
    borderColor: 'white',
    fontSize: '0.75em',
    lineHeight: '1em',
    minHeight: '31px',
    display: 'flex',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'white',
      color: '#029DAF',
    },
    '@media (max-width: 700px)': {
      backgroundImage: 'url(' + FilterIcon + ')',
      fontSize: 0,
      minHeight: '1px',
      minWidth: '1px',
      padding: 0,
      border: 0,
      height: '27px',
      width: '27px',
      transform: 'rotate(90deg)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.2) !important',
      },
    },
  },
  refreshButton: {
    margin: theme.spacing.unit,
    color: 'white',
    marginLeft: '2em',
    borderColor: 'white',
    fontSize: '0.75em',
    lineHeight: '1em',
    minHeight: '31px',
    display: 'flex',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'white',
      color: '#029DAF',
    },
    '@media (max-width: 700px)': {
      backgroundImage: 'url(' + RefreshIcon + ')',
      fontSize: 0,
      minHeight: '1px',
      minWidth: '1px',
      padding: 0,
      border: 0,
      height: '27px',
      width: '27px',
      transform: 'rotate(90deg)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.2) !important',
      },
    },
  },
  selectIcon: {
    color: 'white',
    opacity: 0,
  },
  selectLabel: {
    color: 'white !important',
    fontWeight: 600,
    marginLeft: '1.3em',
    transform: 'none !important',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  selectRoot: {
    fontSize: '13px',
    color: 'white',
  },
  selectClass: {
    marginTop: '0.15em',
    backgroundColor: 'transparent !important',
  },
  menuPaperProps: {
    overflow: 'visible',
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '0px',
      height: '0px',
      left: '25%',
      top: '-16px',
      border: '8px solid transparent',
      borderBottomColor: '#f0f0f0',
    },
  },
  dateFilter: {
    height: '51px',
    borderLeft: '1px solid #42B5C3 !important',
    borderRight: '1px solid #42B5C3 !important',
    padding: '0 1em',
    position: 'relative',
    backgroundImage: 'url(' + caret + ')',
    backgroundSize: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% 70%',
    '& input': {
      appearance: 'none',
      backgroundColor: 'transparent',
      color: 'white',
      border: '0',
      fontFamily: 'Open Sans',
      maxWidth: '120px',
      outline: 'none !important',
    },
    '& label': {
      fontSize: '0.75rem',
      fontWeight: '600',
      display: 'block',
      marginTop: '0.7em',
      marginBottom: '-3px',
    },
    '& ~ &, &:first-of-type:last-of-type': {
      borderLeft: '0 !important',
    },
  },
});

const MySnackBarContent = props => {
  const { message, closeSnack } = props;
  return (
    <SnackbarContent
      message={
        <span
          style={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}
        >
          <WarningIcon style={{ fontSize: '1.3em', marginRight: '1em' }} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={closeSnack}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

class MainAppBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    snackbar: {
      autoHideDuration: 2000,
      text: null,
      action: [],
    },
    startDateOpen: false,
    isMobileFilterOpen: false,
  };

  static FILTERS_NONE = 0;
  static FILTERS_DATE = 1;
  static FILTERS_PERIOD = 2;
  static FILTERS_ALL = 3;

  static filters = {
    orders: MainAppBar.FILTERS_NONE,
    parts: MainAppBar.FILTERS_DATE,
  };

  niceDate() {
    return moment();
  }

  updateData(e) {
    const today = moment();
    const startDate = moment(this.props.picker.start_date);
    const endDate = moment(this.props.picker.end_date);

    if (startDate.isSameOrAfter(endDate)) {
      this.setState({
        ...this.state,
        snackbar: {
          action: [],
          text: 'Start date must be before end date',
        },
      });
      return;
    }

    if (endDate.isAfter(today)) {
      this.setState({
        ...this.state,
        snackbar: {
          action: [],
          text: 'End date cannot be after today.',
        },
      });
      return;
    }

    this.props.updateData(this.props.picker);
  }

  updateMobileData = () => {
    this.updateData();
  };

  onChange = event => {
    this.props.updatePicker({ [event.target.name]: event.target.value });
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onStartDatePickerChange = date => {
    this.props.updatePicker({ start_date: date.format('YYYY-MM-DD') });
  };

  onEndDatePickerChange = date => {
    this.props.updatePicker({ end_date: date.format('YYYY-MM-DD') });
  };

  pageHasFilter(page, filter) {
    const pageFilters =
      MainAppBar.filters[page] == null
        ? MainAppBar.FILTERS_ALL
        : MainAppBar.filters[page];
    return pageFilters & filter;
  }

  openMobileFilter = e => {
    this.setState({ isMobileFilterOpen: true });
  };

  closeMobileFilter = e => {
    this.setState({ isMobileFilterOpen: false });
  };

  closeSnack = () => {
    this.setState({
      snackbar: {
        autoHideDuration: 2000,
        text: null,
        action: [],
      },
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={this.state.autoHideDuration}
          open={this.state.snackbar.text !== null}
          message={this.state.snackbar.text}
          action={this.state.snackbar.action}
        >
          <MySnackBarContent
            message={this.state.snackbar.text}
            closeSnack={this.closeSnack}
          />
        </Snackbar>
        <AppBar id="main-app-bar" className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Hidden lgUp implementation="css">
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={e => this.props.onDrawerToggle()}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <div className={classes.flex} style={{ display: 'flex' }}>
              <Link className={classes.link} to="/">
                <img src={whiteLogo} alt="Perkins Reflects" />
              </Link>
            </div>
            <Hidden mdUp>
              <Button
                className={
                  !this.pageHasFilter(
                    this.props.page,
                    MainAppBar.FILTERS_PERIOD
                  ) &&
                  !this.pageHasFilter(this.props.page, MainAppBar.FILTERS_DATE)
                    ? classes.refreshButton
                    : classes.button
                }
                variant="outlined"
                onClick={e => {
                  if (
                    !this.pageHasFilter(
                      this.props.page,
                      MainAppBar.FILTERS_PERIOD
                    ) &&
                    !this.pageHasFilter(
                      this.props.page,
                      MainAppBar.FILTERS_DATE
                    )
                  ) {
                    this.updateData(e);
                  } else {
                    this.openMobileFilter(e);
                  }
                }}
              >
                {!this.pageHasFilter(
                  this.props.page,
                  MainAppBar.FILTERS_PERIOD
                ) &&
                !this.pageHasFilter(
                  this.props.page,
                  MainAppBar.FILTERS_DATE
                ) ? (
                  'Refresh'
                ) : (
                  <span>
                    Filter
                    <span className="lgtext"> results</span>
                  </span>
                )}
              </Button>
            </Hidden>
            <Hidden smDown>
              <form className={classes.dateFilterForm}>
                {(this.pageHasFilter(
                  this.props.page,
                  MainAppBar.FILTERS_DATE
                ) && (
                  <span style={{ display: 'flex' }}>
                    <div className={classes.dateFilter}>
                      <label>Start Date</label>
                      <DatePicker
                        selected={moment(this.props.picker.start_date)}
                        onChange={this.onStartDatePickerChange.bind(this)}
                        scrollableYearDropdown={true}
                        dateFormat="DD/MM/YYYY"
                        filterDate={date => {
                          return moment() > date;
                        }}
                      />
                    </div>
                    <div className={classes.dateFilter}>
                      <label>End Date</label>
                      <DatePicker
                        selected={moment(this.props.picker.end_date)}
                        onChange={this.onEndDatePickerChange.bind(this)}
                        dateFormat="DD/MM/YYYY"
                        filterDate={date => {
                          return moment() > date;
                        }}
                      />
                    </div>
                  </span>
                )) ||
                  ''}
                {(this.pageHasFilter(
                  this.props.page,
                  MainAppBar.FILTERS_PERIOD
                ) && (
                  <FormControl
                    className={classes.dateFilter}
                    style={{ padding: '0 1em' }}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      htmlFor="age-native-simple"
                    >
                      Period
                    </InputLabel>
                    <Select
                      classes={{
                        root: classes.selectRoot,
                        icon: classes.selectIcon,
                        select: classes.selectClass,
                      }}
                      MenuProps={{
                        className: classes.selectMenuProps,
                        classes: {
                          paper: classes.menuPaperProps,
                        },
                        MenuListProps: {
                          disablePadding: true,
                          dense: true,
                        },
                      }}
                      style={{ minWidth: '100px' }}
                      name="cohort"
                      value={this.props.picker.cohort}
                      onChange={e => this.onChange(e)}
                      inputProps={{
                        id: 'cohort-native-simple',
                      }}
                    >
                      <MenuItem value="day">Daily</MenuItem>
                      <MenuItem value="week">Weekly</MenuItem>
                      <MenuItem value="month">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                )) ||
                  ''}
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={e => {
                    this.updateData(e);
                  }}
                >
                  {!this.pageHasFilter(
                    this.props.page,
                    MainAppBar.FILTERS_PERIOD
                  ) &&
                  !this.pageHasFilter(this.props.page, MainAppBar.FILTERS_DATE)
                    ? 'Refresh'
                    : 'Update'}
                </Button>
              </form>
            </Hidden>
          </Toolbar>
        </AppBar>
        <MobileDateFilterDialog
          showPeriod={this.pageHasFilter(
            this.props.page,
            MainAppBar.FILTERS_PERIOD
          )}
          open={this.state.isMobileFilterOpen}
          onClose={this.closeMobileFilter}
          startDate={this.props.picker.start_date}
          endDate={this.props.picker.end_date}
          cohort={this.props.picker.cohort}
          onChange={this.onChange}
          updateMobileData={this.updateMobileData}
        />
      </div>
    );
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    updatePicker: args => dispatch({ type: 'UPDATE_PICKER', payload: args }),
    updateData: args => {
      dispatch(fetchSales(args));
      dispatch(fetchSalesByAircraft(args));
      dispatch(fetchSalesByCustomers(args));
      dispatch(fetchPartsNotQuoted(args));
      dispatch(fetchPartsEnquired(args, 'all'));
      dispatch(fetchStaffSales(args));
      dispatch(fetchStaffProfits(args));
      dispatch(fetchStaffProfitsSummary(args));
      dispatch(fetchStaffSalesSummary(args));
      dispatch(fetchSectorSales(args));
      dispatch(fetchOrders());
      dispatch(getPartsQuoted(args));
      dispatch(fetchValuations(args));
      dispatch(fetchQuotations(args));
      dispatch(fetchQuotationsSummary(args));
      dispatch(fetchRecentQuotations(args));
      dispatch(fetchOrdersByStoreman());
      dispatch(fetchOrdersBySupplier());
      dispatch(fetchOrderBalance());
      dispatch(fetchCountrySales(args));
    },
  };
};

export default connect(
  store => {
    return {
      page: store.navigation,
      picker: store.picker,
    };
  },
  mapDispatchToProps
)(withRouter(withStyles(styles)(MainAppBar)));
