import { fulfillFromLocal } from './fulfillFromLocal';

export function handleFetchError(type, err, dispatch, loadStale = false) {
  // Not happy with doing this but the client isists
  if (loadStale) {
    return fulfillFromLocal(loadStale, dispatch);
  }
  // TODO: `if (!err.request)` - if an error occurs for a reason other than an error response
  if (err.request && err.request.status === 401) {
    /*
    axios.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem('token');
    dispatch({
      type: 'LOGOUT',
    });
    */
  } else {
    console.error(err);
    dispatch({
      type,
      err,
    });
  }
}
