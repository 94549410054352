import { localLoad } from './localPorrage';

export function fulfillFromLocal(type, dispatch) {
  const payload = localLoad(type);
  if (payload) {
    return dispatch({
      type,
      payload,
    });
  }
  return false;
}
