import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../Loader';
import StockValuationsTable from '../StockValuationsTable';
import StockValuationsLine from '../StockValuationsLine';
import Typography from '@material-ui/core/Typography';
import SummaryCard from '../SummaryCard';

class Stock extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const { valuations, loading } = this.props;
    if (!valuations.history.length) {
      return <Loader />;
    }
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Stock
        </Typography>
        <Grid container spacing={24} justify="flex-start" direction="row">
          <Grid item xs={12} md={6} lg={4}>
            {loading ? (
              <Card>
                <CardContent>
                  <Loader />
                </CardContent>
              </Card>
            ) : (
              <SummaryCard
                data={valuations.today}
                text="Total valuation"
                icon="stock"
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                {loading ? (
                  <React.Fragment>
                    <Loader />
                  </React.Fragment>
                ) : (
                  <StockValuationsTable data={valuations.history} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                {loading ? (
                  <React.Fragment>
                    <Loader />
                  </React.Fragment>
                ) : (
                  <StockValuationsLine data={valuations.history} />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'stock' }),
  };
};

export default connect(store => {
  return {
    valuations: store.parts.partsValuations,
    loading: store.parts.partsValuationsLoading,
  };
}, mapDispatchToProps)(Stock);
