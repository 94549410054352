import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import {
  authUser,
  fetchOrderBalance,
  fetchOrders,
  fetchOrdersByStoreman,
  fetchOrdersBySupplier,
  fetchPartsEnquired,
  fetchPartsNotQuoted,
  fetchQuotations,
  fetchQuotationsSummary,
  fetchRecentQuotations,
  fetchSales,
  fetchSectorSales,
  fetchSalesByAircraft,
  fetchStaffSales,
  fetchStaffProfits,
  fetchStaffProfitsSummary,
  fetchStaffSalesSummary,
  fetchValuations,
  getPartsQuoted,
  fetchCountrySales,
  fetchSalesByCustomers,
} from '../../actions/InitActions';
import 'animate.css/animate.min.css';
import logo from '../../assets/perkins_logo_blue_with_hg@2x.png';
import Loader from '../Loader';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TERTIARY } from '../../palette';

class Splash extends React.Component {
  constructor(props) {
    super(props);

    const token =
      localStorage.getItem('token') || sessionStorage.getItem('token');
    const tokenStored = token !== null;

    this.state = {
      showLoader: tokenStored,
      credentials: { username: '', password: '', saveUser: '' },
    };

    if (tokenStored) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const userState = jwt_decode(token);
      this.state.credentials = userState.user;
      this.props.authStoredUser(userState);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user.loader === 1) {
      // TODO move default args to state.
      this.props.loadAll();
    }
  }

  submit(e) {
    if (this.props.user.authenticating) return;
    e.preventDefault();
    this.props.login(this.state.credentials);
  }

  onChange(field, e) {
    const credentials = this.state.credentials;
    credentials[field] =
      e.target[e.target.type === 'checkbox' ? 'checked' : 'value'];
    this.setState({ credentials: credentials });
    this.props.user.loginFailed = false;
  }

  isIe() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    return false;
  }

  render() {
    //120
    const waveHtml = `
    <svg viewBox="0 0 120 28">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="
              1 0 0 0 0
              0 1 0 0 0
              0 0 1 0 0
              0 0 0 13 -9" result="goo" />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
        <path id="wave" d="
          M 0,10
          C 30,10 30,17 60,17 90,17 90,10 120,10 150,10 150,17 180,17 210,17 210,10 240,10
          v 28
          h -240
          z" />
      </defs>


        <g id="wavegroup1" class="gooef">
          <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0" ></use>

          <circle cx="60" cy="17" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
          </circle>

          <circle cx="180" cy="17" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
          </circle>
        </g>


        <g id="wavegroup2">
          <g transform="translate(0,-4)">
            <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0" ></use>
            <circle cx="0" cy="10" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>

            <circle cx="120" cy="10" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>

            <circle cx="60" cy="17" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>

            <circle cx="180" cy="17" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>

            <circle cx="240" cy="10" r="0.5" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>
          </g>
        </g>


        <g id="wavegroup3">
          <g transform="translate(0,1)">
            <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0" ></use>
          </g>
        </g>


        <g id="wavegroup4" class="goeff">
          <g transform="translate(0,-5)">
            <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0" ></use>
            <circle cx="0" cy="10" r="0.4" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>

            <circle cx="120" cy="10" r="0.4" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>


            <circle cx="240" cy="10" r="0.4" stroke="#0B8CA2" stroke-width="0.1" fill="rgba(255,255,255,0.3)">
            </circle>
          </g>
        </g>

    </svg>
    `;
    return (
      <div className="Splash__container full-height">
        <Grid container direction="row" justify="flex-end">
          <Grid className="Splash__bg" item xs={12} sm={6}>
            {!this.isIe() ? (
              <div
                className="Waves__container"
                dangerouslySetInnerHTML={{ __html: waveHtml }}
              />
            ) : (
              <div className="iebg" />
            )}
          </Grid>
          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={6}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paper elevation={0} className="Splash__card">
                <img src={logo} alt="Perkns Reflects" />
                <div className="Splash__inner">
                  {this.props.user.loader < 1 || !this.props.user.user ? (
                    <form onSubmit={e => this.submit(e)}>
                      <Typography>
                        Welcome back! Please login to your account
                      </Typography>
                      {/* <Typography variant="title" style={{ fontFamily: 'Roboto Slab' }}>Perkins Reflects</Typography>
                      <Typography variant="subheading">Work in Progress<br />Version {version}</Typography> */}
                      <TextField
                        className="Splash__input"
                        fullWidth
                        margin="normal"
                        label="Username"
                        value={this.state.credentials.username}
                        onChange={e => this.onChange('username', e)}
                        id="f_username"
                        required={true}
                      />
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Password"
                        type="password"
                        value={this.state.credentials.password}
                        onChange={e => this.onChange('password', e)}
                        id="f_password"
                        required={true}
                      />
                      <div>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={this.state.credentials.saveUser}
                                onChange={e => this.onChange('saveUser', e)}
                              />
                            }
                            label="Remember me"
                          />
                        </Grid>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <Button
                          className={
                            'Splash__button ' +
                            (this.props.user.loginFailed && 'animated shake')
                          }
                          primary="true"
                          color="primary"
                          variant="contained"
                          style={{ margin: `2em 10px 10px 10px` }}
                          type="submit"
                        >
                          Login
                          {this.props.user.authenticating && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: TERTIARY,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                              }}
                            />
                          )}
                        </Button>
                        {this.state.authenticating && (
                          <CircularProgress size={24} />
                        )}
                      </div>
                    </form>
                  ) : (
                    <div style={{ color: '#575E7B' }}>
                      <Loader style={{ marginBottom: '2em' }} />
                      Welcome back, {this.state.credentials.username}!
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    login: credentials => dispatch(authUser(credentials)),
    loadAll: args => {
      dispatch(fetchSales(args));
      dispatch(fetchSalesByAircraft(args));
      dispatch(fetchSalesByCustomers(args));
      dispatch(fetchPartsNotQuoted(args));
      dispatch(fetchPartsEnquired(args, 'all'));
      dispatch(fetchStaffSales(args));
      dispatch(fetchStaffProfits(args));
      dispatch(fetchStaffProfitsSummary(args));
      dispatch(fetchSectorSales(args));
      dispatch(fetchStaffSalesSummary(args));
      dispatch(fetchOrders());
      dispatch(getPartsQuoted(args));
      dispatch(fetchValuations(args));
      dispatch(fetchQuotations(args));
      dispatch(fetchQuotationsSummary(args));
      dispatch(fetchRecentQuotations(args));
      dispatch(fetchOrdersBySupplier());
      dispatch(fetchOrdersByStoreman());
      dispatch(fetchOrderBalance());
      dispatch(fetchCountrySales(args));
    },
    authStoredUser: userState =>
      dispatch({
        type: 'AUTH_SUCCESS',
        user: userState.user,
        scopes: userState.scopes,
      }),
  };
};

const mapStateToProps = store => {
  return { user: store.user };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Splash);
