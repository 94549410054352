import React from 'react';
import ReBarChart from './charts/reBar';

const PartsEnquiredBarChart = ({
  partsEnquiredAll,
  animationDuration,
  isPresentation,
  subHeading,
}) => {
  if (!partsEnquiredAll) return '';
  const data = {
    labels: partsEnquiredAll.slice(0, 8).map(part => part.partNumber),
    data: partsEnquiredAll.slice(0, 8).map(part => part.count),
  };
  return (
    <div>
      <ReBarChart
        isPresentation={isPresentation}
        animationDuration={animationDuration}
        chartData={data}
        heading="Top 8 parts Enquired"
        subHeading={subHeading}
      />
    </div>
  );
};

export default PartsEnquiredBarChart;
