import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SalesLine from '../SalesLine';
import ProfitLine from '../ProfitLine';
import SalesTotals from '../SalesTotals';
import Loader from '../Loader';
import Typography from '@material-ui/core/Typography';
import SummaryCard from '../SummaryCard';
import SalesAircraftTable from '../tables/SalesAircraftTable';
import SectorSalesDonut from '../sectorSalesDonut';
import AircraftDonut from '../aircraftDonut';
import { resultCard } from '../utils/card';
import { currencyFormat, longIntFormat } from '../../constants';
import { getTotal } from '../../utils/getTotal';

class Staff extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const { sales, salesAircraft, sectorSales } = this.props;
    const grossProfit =
      sales.reduce((acc, cur) => {
        return acc + (cur.total * 100 - cur.cost * 100);
      }, 0) / 100;
    if (!sales.length) {
      return <Loader />;
    }
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Sales
        </Typography>
        <Grid container spacing={24} justify="flex-start" direction="row">
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SummaryCard
              data={sales}
              type="total"
              text="Sales"
              icon="sales"
              format={currencyFormat}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SummaryCard
              data={grossProfit}
              type="profit"
              text="Gross profit"
              icon="profit"
              format={currencyFormat}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <SummaryCard
              data={sales}
              type="orders"
              text="Orders"
              icon="order"
            />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SalesLine dataset={sales} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <ProfitLine dataset={sales} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SalesTotals sales={sales} />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SectorSalesDonut sectorSales={sectorSales} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            {resultCard('Sales by Aircraft', salesAircraft, SalesAircraftTable)}
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                {salesAircraft && (
                  <AircraftDonut
                    aircraftSales={salesAircraft.results}
                    totalText={`$${longIntFormat.format(getTotal(sales, 'total'))}`}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'staff' }),
  };
};

export default connect(
  store => {
    return {
      sales: store.sales.all,
      salesAircraft: {
        results: store.sales.aircraft,
        error: store.sales.aircraftError,
        loading: store.sales.aircraftFetching,
      },
      sectorSales: store.sales.sector,
    };
  },
  mapDispatchToProps
)(Staff);
