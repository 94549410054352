import axios from 'axios';
import { fetchUsers } from './InitActions';

const API = process.env.REACT_APP_DOMAIN;

export function createUser(user) {
  return function(dispatch, getState) {
    dispatch({ type: 'USER_UPDATING' });
    axios
      .post(`${API}/user/`, user)
      .then(response => {
        dispatch({
          type: 'USER_UPDATE_FULFILLED',
          payload: response,
        });
        dispatch(fetchUsers());
      })
      .catch(err => {
        dispatch({
          type: 'USER_CREATE_ERROR',
          payload: err,
        });
      });
  };
}
//update user from component

export function updateUser(user) {
  return function(dispatch) {
    dispatch({
      type: 'USER_UPDATING',
    });
    axios
      .patch(`${API}/user/${user.id}`, user)
      .then(response => {
        dispatch({
          type: 'USER_UPDATE_FULFILLED',
          payload: response,
        });
        dispatch(fetchUsers());
      })
      .catch(err => {
        dispatch({
          type: 'USER_UPDATE_ERROR',
          payload: err,
        });
      });
  };
}

export function deleteUser(user) {
  return function(dispatch, getState) {
    dispatch({
      type: 'USER_UPDATING',
    });
    axios
      .delete(`${API}/user/${user.id}`)
      .then(response => {
        dispatch({
          type: 'USER_UPDATE_FULFILLED',
          payload: response,
        });
        dispatch(fetchUsers());
      })
      .catch(err => {
        dispatch({
          type: 'USER_DELETE_ERROR',
          payload: err,
        });
      });
  };
}

export function logout() {
  return function(dispatch) {
    axios.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    return dispatch({
      type: 'LOGOUT',
    });
  }
}
