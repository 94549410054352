export function formatStaffSales(data) {
  const dataArr = Object.values(data);
  const formattedData = {};
  const arrDates = Object.keys(data);
  formattedData.storemen = dataArr[0].storemen;
  formattedData.dates = arrDates.map(date => {
    return {
      date,
      data: data[date],
    };
  });
  return formattedData;
}
