const defaultState = {
  all: {
    results: [],
    error: null,
    loading: true,
  },
  pastDatedue: {
    results: [],
    error: null,
    loading: true,
  },
  futureDatedue: {
    results: [],
    error: null,
    loading: true,
  },
  storeman: {
    results: [],
    error: null,
    loading: true,
  },
  supplier: {
    results: [],
    error: null,
    loading: true,
  },
  balance: {
    value: null,
    error: null,
    loading: true,
  },
};

export default function sales(state = defaultState, action) {
  switch (action.type) {
    case 'ORDERS_FETCHING':
      return {
        ...state,
        all: { loading: true, error: null, results: state.all.results },
      };
    case 'ORDERS_FULFILLED':
      return {
        ...state,
        all: { loading: false, error: null, results: action.payload },
      };
    case 'ORDERS_ERROR':
      return {
        ...state,
        all: {
          loading: false,
          error: action.payload,
          results: state.all.results,
        },
      };
    case 'PAST_DATEDUE_ORDERS_FETCHING':
      return {
        ...state,
        pastDatedue: { loading: true, error: null, results: state.pastDatedue.results },
      };
    case 'PAST_DATEDUE_ORDERS_FULFILLED':
      return {
        ...state,
        pastDatedue: { loading: false, error: null, results: action.payload },
      };
    case 'PAST_DATEDUE_ORDERS_ERROR':
      return {
        ...state,
        pastDatedue: {
          loading: false,
          error: action.payload,
          results: state.pastDatedue.results,
        },
      };
    case 'FUTURE_DATEDUE_ORDERS_FETCHING':
      return {
        ...state,
        futureDatedue: { loading: true, error: null, results: state.futureDatedue.results },
      };
    case 'FUTURE_DATEDUE_ORDERS_FULFILLED':
      return {
        ...state,
        futureDatedue: { loading: false, error: null, results: action.payload },
      };
    case 'FUTURE_DATEDUE_ORDERS_ERROR':
      return {
        ...state,
        futureDatedue: {
          loading: false,
          error: action.payload,
          results: state.futureDatedue.results,
        },
      };
    case 'ORDERS_STOREMAN_FETCHING':
      return {
        ...state,
        storeman: {
          loading: true,
          error: null,
          results: state.storeman.results,
        },
      };
    case 'ORDERS_STOREMAN_FULFILLED':
      return {
        ...state,
        storeman: { loading: false, error: null, results: action.payload },
      };
    case 'ORDERS_STOREMAN_ERROR':
      return {
        ...state,
        storeman: {
          loading: false,
          error: action.payload,
          results: state.storeman.results,
        },
      };
    case 'ORDERS_SUPPLIER_FETCHING':
      return {
        ...state,
        supplier: {
          loading: true,
          error: null,
          results: state.supplier.results,
        },
      };
    case 'ORDERS_SUPPLIER_FULFILLED':
      return {
        ...state,
        supplier: { loading: false, error: null, results: action.payload },
      };
    case 'ORDERS_SUPPLIER_ERROR':
      return {
        ...state,
        supplier: {
          loading: false,
          error: action.payload,
          results: state.supplier.results,
        },
      };
    case 'ORDERS_BALANCE_FETCHING':
      return {
        ...state,
        balance: {
          loading: true,
          error: null,
          value: state.balance.value,
        },
      };
    case 'ORDERS_BALANCE_FULFILLED':
      return {
        ...state,
        balance: { loading: false, error: null, value: action.payload },
      };
    case 'ORDERS_BALANCE_ERROR':
      return {
        ...state,
        balance: {
          loading: false,
          error: action.payload,
          value: state.balance.value,
        },
      };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
