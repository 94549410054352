import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const DEV = process.env.NODE_ENV !== 'production';
let middleware = DEV ? [thunk, logger] : [thunk];

let enhancer = applyMiddleware(...middleware);
if (DEV) enhancer = composeWithDevTools(enhancer);

const store = createStore(rootReducer, enhancer);

export default store;
