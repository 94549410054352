import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Loader from '../Loader';
import GenericError from '../GenericError';

const card = (content, props = { xs: 12, sm: 12 }, contentProps = {}) => (
  <Grid item {...props}>
    <Card>
      <CardContent {...contentProps}>{content}</CardContent>
    </Card>
  </Grid>
);

/**
 * Renders a card which displays a view based on a list.
 * This component handles loading indicators and displaying errors.
 *
 * @param title The text to show at the top of the card
 * @param context An object consisting of the state of the data being displayed. They are mapped from the following properties: results, error, loading
 * @param ComposedComponent The component to render within the card, results will be passed in to the `data` property.
 * @param cardProps Properties to apply to the card component itself (i.e. for resizing)
 * @param contentProps Properties to apply to the inner CardContent component
 * @returns {*} Complete card component
 */
function resultCard(
  title,
  context,
  ComposedComponent,
  cardProps,
  contentProps
) {
  const { results, error, loading } = context;
  if (loading) {
    return card(<Loader />, cardProps, contentProps);
  }
  if (error) {
    return card(
      <GenericError title={title}>
        An error has occurred while loading this report.
      </GenericError>,
      cardProps
    );
  }
  if (results.length === 0) {
    return card(<GenericError title={title} />, cardProps);
  }
  return card(<ComposedComponent title={title} data={results} />, cardProps);
}

export { card, resultCard };
