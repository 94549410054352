import React from 'react';
import ReBarChart from './charts/reBar';

const StaffSalesBarChart = ({
  staffSalesTotals,
  animationDuration,
  isPresentation,
}) => {
  if (!staffSalesTotals) return '';
  const data = {
    labels: staffSalesTotals.map(staff => staff.storeman),
    data: staffSalesTotals.map(staff => staff.total),
  };
  return (
    <div>
      <ReBarChart
        isPresentation={isPresentation}
        animationDuration={animationDuration}
        chartData={data}
        heading="Staff Sales Totals"
        isCurrency={true}
      />
    </div>
  );
};

export default StaffSalesBarChart;
