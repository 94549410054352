import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';
import shortid from 'shortid';
import { currencyFormat } from '../../constants';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '../../../node_modules/@material-ui/icons/LastPage';
import FirstPageIcon from '../../../node_modules/@material-ui/icons/FirstPage';
import KeyboardArrowRight from '../../../node_modules/@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '../../../node_modules/@material-ui/icons/KeyboardArrowLeft';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  presentationCell: {
    fontSize: '1vw !important',
    paddingTop: '1.25vh !important',
    paddingBottom: '1.25vh !important',
    fontWeight: 'bold',
  },
  underline: {
    '&:hover': {
      borderBottom: '2px solid rgba(2, 157, 175, 0.7)',
    },
    '&:before': {
      borderBottom: '2px solid rgba(2, 157, 175, 0.7)',
    },
    '&:after': {
      borderBottom: '2px solid rgba(2, 157, 175, 0.7)',
    },
  },
  textField: {
    fontSize: '0.75rem !important',
    paddingLeft: '0.5em',
  },
});

const currencyFields = ['minimum', 'maximum', 'average', 'total'];

class PartsTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: this.props.data,
      page: 0,
      rowsPerPage: 5,
      filter: '',
    };
  }

  handleChangePage = (e, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.data) {
      return this.setState({ data: nextProps.data });
    }
  }

  updateFilter = event => {
    this.setState({ filter: event.target.value });
  };

  filterPartNumber = part =>
    this.state.filter === ''
      ? true
      : part.partNumber.toLowerCase().startsWith(this.state.filter.toLowerCase());

  render() {
    const thead = Object.keys(this.state.data[0]);
    const { page, rowsPerPage } = this.state;
    const { isPresentation, classes, subHeading, excludedFields } = this.props;

    const data = this.state.data.filter(this.filterPartNumber);

    return (
      <div>
        {isPresentation ? (
          <div>
            <Typography
              align="center"
              variant="display2"
              style={{ marginBottom: subHeading.length ? '0.5em' : '1em' }}
            >
              {this.props.heading}
            </Typography>
            {subHeading.length ? (
              <Typography
                align="center"
                variant="display1"
                style={{ marginBottom: '1em' }}
              >
                {subHeading}
              </Typography>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="headline"
              children={this.props.heading}
              align="left"
              gutterBottom
            />
            <div style={{ marginBottom: '0.25em' }}>
              <TextField
                onChange={this.updateFilter}
                value={this.state.filter}
                placeholder="Filter by Part Number"
                InputProps={{
                  classes: {
                    input: classes.textField,
                  },
                }}
              />
            </div>
          </div>
        )}

        <div
          className={this.props.classes.tableWrapper}
          style={{ flexGrow: 1, width: '100%' }}
        >
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                {thead.filter(i => !excludedFields.includes(i)).map(k => (
                  <TableCell
                    className={isPresentation ? classes.presentationCell : ''}
                    key={shortid.generate()}
                  >
                    {k}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow key={shortid.generate()}>
                      {thead.filter(i => !excludedFields.includes(i)).map(k => {
                        return (
                          <TableCell
                            className={
                              isPresentation ? classes.presentationCell : ''
                            }
                            component="th"
                            scope="row"
                            key={shortid.generate()}
                          >
                            {currencyFields.includes(k)
                              ? currencyFormat.format(n[k])
                              : n[k]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
            {!isPresentation ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            ) : (
              <TableFooter />
            )}
          </Table>
        </div>
      </div>
    );
  }
}

PartsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

PartsTable.defaultProps = {
  rowsPerPage: 5,
  isPresentation: false,
  subHeading: '',
  excludedFields: ['date'],
};

export default withStyles(styles)(PartsTable);
