import React from 'react';
import ReDoughnutChart from './charts/reDoughnut';

const PartEnquiredDonut = ({ parts, isPresentation }) => {
  const data = {
    labels: parts.map(part => part.partNumber),
    data: parts.map(part => part.count),
  };
  return (
    <div>
      <ReDoughnutChart
        linkTo={'/parts'}
        centerText={'Enquiries'}
        isCurrency={false}
        chartData={data}
        isPresentation={isPresentation}
        headline="Top 5 Parts Enquired"
      />
    </div>
  );
};

export default PartEnquiredDonut;
