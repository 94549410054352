export const API = process.env.REACT_APP_DOMAIN;
export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
});
export const longNumberFormat = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});
export const longIntFormat = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const BUILD_INFO = {
  build: process.env.BUILD_BUILDNUMBER || "",
  timestamp: process.env.RELEASE_DEPLOYMENT_STARTTIME || "",
  agent: {
    name: process.env.AGENT_NAME || process.env.USER || "",
    machine: process.env.AGENT_MACHINENAME || process.env.HOSTNAME || "",
    nodejs: process.version || "",
    versions: process.versions,
    platform: process.platform || "",
  },
  branch: process.env.BUILD_SOURCEBRANCHNAME || "",
  commit: process.env.BUILD_SOURCEVERSION || "",
};
