import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { currencyFormat } from '../../constants';
import shortid from 'shortid';

function SalesDetailTable({ data, classes }) {
  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.bgLight}>Job No.</TableCell>
            <TableCell className={classes.bgLight}>Customer</TableCell>
            <TableCell className={classes.bgLight}>Description</TableCell>
            <TableCell className={classes.bgLight}>Part No.</TableCell>
            <TableCell className={classes.bgLight}>Quantity</TableCell>
            <TableCell className={classes.bgLight}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(order => {
            if (order.totalSale === 0) return false;
            return (
              <TableRow key={shortid.generate()}>
                <TableCell className={classes.borderless}>
                  {order.jobno}
                </TableCell>
                <TableCell className={classes.borderless}>
                  {order.cust}
                </TableCell>
                <TableCell className={classes.borderless}>
                  {order.descr}
                </TableCell>
                <TableCell className={classes.borderless}>
                  {order.partno}
                </TableCell>
                <TableCell className={classes.borderless}>
                  {order.qty}
                </TableCell>
                <TableCell className={classes.borderless}>
                  {currencyFormat.format(order.totalSale)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
    margin: '0 5px 5px 5px',
    width: 'calc(100% - 10px)',
    boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.1)',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  borderless: {
    borderBottom: 0,
  },
  bgLight: {
    backgroundColor: 'transparent',
    borderBottom: 0,
  },
});

SalesDetailTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SalesDetailTable);
