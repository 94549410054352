const defaultState = {
  total: [],
  detail: [],
  totalLoading: false,
  detailLoading: false,
};

export default function quotations(state = defaultState, action) {
  switch (action.type) {
    case 'QUOTATIONS_FETCHING':
      return { ...state, totalLoading: true, status: 'FETCHING' };
    case 'QUOTATIONS_FULFILLED':
      return {
        ...state,
        totalLoading: false,
        detail: action.payload,
        status: 'FULFILLED',
      };
    case 'QUOTATIONS_ERROR':
      return { ...state, totalLoading: false, error: action.payload };
    case 'QUOTATIONS_SUMMARY_FETCHING':
      return { ...state, loading: true };
    case 'QUOTATIONS_SUMMARY_FULFILLED':
      return { ...state, loading: false, total: action.payload };
    case 'QUOTATIONS_SUMMARY_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'RECENT_QUOTATIONS_FETCHING':
      return { ...state, loading: true };
    case 'RECENT_QUOTATIONS_FULFILLED':
      return { ...state, loading: false, recent: action.payload };
    case 'RECENT_QUOTATIONS_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
