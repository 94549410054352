import React, { Component } from 'react';
import Loader from './Loader';
import ReLineChart from './charts/reLine';

class ProfitLine extends Component {
  state = {
    showNegatives: false,
  };
  onToggle() {
    this.setState({ showNegatives: !this.state.showNegatives });
  }
  render() {
    const {
      dataset,
      hideToggle,
      animationDuration,
      isPresentation,
      subHeading,
    } = this.props;
    const newData = [
      {
        label: 'USD',
        data: dataset.map(item => ({
          x: item.date,
          y: item.total - item.cost,
        })),
      },
    ];
    return dataset.length === 0 ? (
      <Loader />
    ) : (
      <div>
        <ReLineChart
          onToggle={
            hideToggle === undefined ? this.onToggle.bind(this) : undefined
          }
          domain={!this.state.showNegatives ? [0, 'auto'] : ['dataMin', 'auto']}
          margin={{ top: 20, right: 0, left: -10, bottom: 0 }}
          heading={'Gross Profit'}
          dataset={newData}
          animationDuration={animationDuration}
          isCurrency={false}
          isPresentation={isPresentation}
          subHeading={subHeading}
        />
      </div>
    );
  }
}

export default ProfitLine;
