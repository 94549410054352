export const localLoad = item => {
  try {
    const serializedState = localStorage.getItem(item);
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const localSave = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.error(err);
  }
};

export const localRemove = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};
