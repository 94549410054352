import React from 'react';
import Sales from './pages/Sales';
import Staff from './pages/Staff';
import Home from './pages/Home';
import MainAppBar from './MainAppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, Route, Switch } from 'react-router-dom';
import Parts from './pages/Parts';
import Stock from './pages/Stock';
import Quotes from './pages/Quotes';
import OrderBook from './pages/Orders';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import Presentation from './pages/Presentation';
import Customers from './pages/Customers';

import { Book, PeopleOutline } from '@material-ui/icons';
import EditAccountDialog from './dialogs/EditAccountDialog';
import axios from 'axios';
import Users from './pages/Users';
import ReleaseNotes from './pages/ReleaseNotes';
import { hasScope } from './utils/auth';
import IconButton from '@material-ui/core/IconButton';
import VersionInfo from './utils/VersionInfo';
import {
  CustomHomeIcon,
  CustomSalesIcon,
  CustomStaffIcon,
  CustomQuoteIcon,
  CustomStockIcon,
  CustomPartsIcon,
  CustomCollapseIcon,
  CustomAccountIcon,
  CustomEditIcon,
  CustomReleaseNotesIcon,
  CustomLogoutIcon,
  CustomPresentationIcon,
  CustomCustomersIcon,
} from '../misc/Icons';
import { fetchUsers } from '../actions/InitActions';
import { logout } from '../actions/UserActions';

const styles = theme => ({
  customIcon: {
    marginRight: '16px',
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  drawerPaper: {
    width: '240px',
    backgroundColor: '#323D64',
    paddingTop: '54px',
    color: 'white',
    overflow: 'hidden',
    zIndex: 900,
    height: 'calc(var(--vh, 1vh) * 100)',
    '& span': {
      color: 'white !important',
      fontSize: '0.9rem !important',
      fontWeight: 100,
    },
    '& path': {
      fill: 'white !important',
    },
    '& a': {
      borderBottom: '1px solid #575E7B',
    },
    '& svg': {
      marginRight: '0 !important',
    },
    '@media (max-width: 1280px)': {
      width: 'auto',
      '& a > div': {
        display: 'none',
      },
      '& .account_menu': {
        display: 'none',
      },
    },
  },
  mobileDrawerPaper: {
    width: '240px',
    backgroundColor: '#323D64',
    paddingTop: '0',
    color: 'white',
    overflow: 'hidden',
    height: 'calc(var(--vh, 1vh) * 100)',
    '& span': {
      color: 'white !important',
      fontSize: '0.9rem !important',
      fontWeight: 100,
    },
    '& path': {
      fill: 'white !important',
    },
    '& a': {
      borderBottom: '1px solid #575E7B',
      '&:first-of-type': {
        borderTop: '1px solid #575E7B',
      },
    },
  },
  drawer: {
    width: '240px',
    zIndex: 1000,
  },
  activeTab: {
    backgroundColor: '#2A3357',
  },
  accountMenu: {
    position: 'absolute',
    width: '100%',
    top: '100%',
    transform: 'translateY(-72px)',
    backgroundColor: 'rgba(0, 0, 0, 0.29)',
    padding: 0,
  },
  materialIcon: {
    fill: 'none',
    color: 'white !important',
  },
  accountMenuSecondaryText: {
    color: 'white !important',
  },
  drawerDocked: {
    width: '240px',
    overflow: 'hidden',
    '@media (max-width: 1280px)': {
      width: '78px',
    },
  },
});

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      drawerOpen: false,
      editAccountDialogUser: null,
      showLogoutDialog: false,
      accountMenuOpen: false,
    };
  }

  accountMenuToggle = () => {
    this.props.fetchUsers();
    this.setState({ accountMenuOpen: !this.state.accountMenuOpen });
  };

  drawerToggle = (value = null) => {
    if (value === null) {
      this.setState({ drawerOpen: !this.state.drawerOpen });
    } else {
      this.setState({ drawerOpen: false });
      // this.setState({ drawerOpen: value });
    }
  };

  editAccountClose = value => {
    this.setState({ editAccountDialogUser: null });
  };

  logoutAction = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  render() {
    if (
      this.props.user.user
      && this.props.user.user.role === 'presentation'
      && window.location.pathname !== '/presentation'
    ) {
      this.props.history.push('/presentation');
    }

    const { classes } = this.props;

    const divStyle = {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '90px',
      paddingLeft: '2vw',
      paddingRight: '2vw',
      paddingBottom: '2vw',
      flexGrow: 1,
    };

    const activeTab = (path, props) => {
      if (props.location.pathname !== undefined) {
        return props.location.pathname === path
          ? classes.activeTab + ' active-tab'
          : 'inactive-tab';
      }
      return classes.activeTab;
    };

    const drawer = (
      <div
        tabIndex={0}
        role="button"
        // onClick={e => this.drawerToggle(e)}
      >
        <List disablePadding={true} onClick={e => this.drawerToggle(false)}>
          <ListItem
            button
            component={Link}
            to="/"
            className="menu-tab"
            classes={{
              root: activeTab('/', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomHomeIcon />
            </SvgIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/sales"
            className="menu-tab"
            classes={{
              root: activeTab('/sales', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomSalesIcon />
            </SvgIcon>
            <ListItemText primary="Sales" />
          </ListItem>
          {hasScope('manage staff', this.props.user.scopes) && (
            <ListItem
              button
              component={Link}
              to="/staff"
              className="menu-tab"
              classes={{
                root: activeTab('/staff', this.props),
              }}
            >
              <SvgIcon className={classes.customIcon}>
                <CustomStaffIcon />
              </SvgIcon>
              <ListItemText primary="Staff" />
            </ListItem>
          )}
          <ListItem
            button
            component={Link}
            to="/quotations"
            className="menu-tab"
            classes={{
              root: activeTab('/quotations', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomQuoteIcon />
            </SvgIcon>
            <ListItemText primary="Quotations" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/orders"
            className="menu-tab"
            classes={{
              root: activeTab('/orders', this.props),
            }}
          >
            <ListItemIcon>
              <Book />
            </ListItemIcon>
            <ListItemText primary="Order Book" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/stock"
            className="menu-tab"
            classes={{
              root: activeTab('/stock', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomStockIcon />
            </SvgIcon>
            <ListItemText primary="Stock" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/parts"
            className="menu-tab"
            classes={{
              root: activeTab('/parts', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomPartsIcon />
            </SvgIcon>
            <ListItemText primary="Parts" />
          </ListItem>

          {hasScope('customer sales', this.props.user.scopes) && (
            <ListItem
              button
              component={Link}
              to="/customers"
              className="menu-tab"
              classes={{
                root: activeTab('/customers', this.props),
              }}
            >
              <SvgIcon className={classes.customIcon}>
                <CustomCustomersIcon />
              </SvgIcon>
              <ListItemText primary="Customers" />
            </ListItem>
          )}

          <ListItem
            button
            component={Link}
            to="/presentation"
            className="menu-tab"
            classes={{
              root: activeTab('/presentation', this.props),
            }}
          >
            <SvgIcon className={classes.customIcon}>
              <CustomPresentationIcon />
            </SvgIcon>
            <ListItemText primary="Presentation" />
          </ListItem>
        </List>
        {hasScope('debug', this.props.user.scopes) && <VersionInfo />}
        <List
          className={[
            classes.accountMenu,
            'account_menu',
            this.state.accountMenuOpen ? 'open' : 'closed',
          ].join(' ')}
        >
          <ListItem>
            <ListItemIcon>
              <SvgIcon>
                <CustomAccountIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                this.props.user.user !== null
                  ? this.props.user.user.username
                  : ''
              }
              primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
              secondary={
                this.props.user.user !== null ? this.props.user.user.role : ''
              }
              secondaryTypographyProps={{
                className: classes.accountMenuSecondaryText,
              }}
            />
            <IconButton
              className="account_menu--collapse"
              onClick={this.accountMenuToggle}
            >
              <SvgIcon>
                <CustomCollapseIcon />
              </SvgIcon>
            </IconButton>
          </ListItem>
          <ListItem
            button
            onClick={e => {
              this.setState({ editAccountDialogUser: this.props.user.user });
              this.drawerToggle(false);
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <CustomEditIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Edit account" />
          </ListItem>
          {hasScope('manage users', this.props.user.scopes) && (
            <ListItem
              button
              component={Link}
              to="/admin/users"
              onClick={e => this.drawerToggle(false)}
            >
              <ListItemIcon>
                <PeopleOutline className={classes.materialIcon} />
              </ListItemIcon>
              <ListItemText primary="User management" />
            </ListItem>
          )}
          <ListItem
            button
            component={Link}
            to="/releasenotes"
            onClick={e => this.drawerToggle(false)}
          >
            <ListItemIcon>
              <SvgIcon>
                <CustomReleaseNotesIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Release Notes" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <SvgIcon className={classes.customIcon}>
                <CustomLogoutIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Log out" onClick={this.logoutAction} />
          </ListItem>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <MainAppBar onDrawerToggle={this.drawerToggle} />

        {/* Mobile Drawer */}
        <Drawer
          // variant="permanent"
          variant="temporary"
          open={this.state.drawerOpen}
          anchor="left"
          style={{ width: 240, overflow: 'hidden' }}
          onClose={e => this.drawerToggle(e)}
          classes={{
            paper: classes.mobileDrawerPaper,
          }}
        >
          <IconButton
            style={{ marginLeft: '3px' }}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={e => {
              this.drawerToggle(e);
            }}
          >
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
        {/* Desktop Drawer */}
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
              docked: classes.drawerDocked,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <main className="App" style={divStyle}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/sales" component={Sales} />
            <Route path="/staff" component={Staff} />
            <Route path="/parts" component={Parts} />
            <Route path="/stock" component={Stock} />
            <Route path="/quotations" component={Quotes} />
            <Route path="/orders" component={OrderBook} />
            <Route path="/admin/users" component={Users} />
            <Route path="/releasenotes" component={ReleaseNotes} />
            <Route path="/presentation" component={Presentation} />
            <Route path="/customers" component={Customers} />
          </Switch>
        </main>

        <EditAccountDialog
          onClose={this.editAccountClose}
          open={this.state.editAccountDialogUser !== null}
          user={this.state.editAccountDialogUser}
        />
      </div>
    );
  }
}

const dispatchProps = function(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = function(store) {
  return { user: store.user };
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  dispatchProps
)(withStyles(styles)(Layout));
