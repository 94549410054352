import React from 'react';
import { withRouter } from 'react-router-dom';
import ReDoughnutChart from './charts/reDoughnut';
import { currencyFormat } from '../constants';

const StaffSalesDonut = ({ staffSalesTotals, link, title }) => {
  if (!staffSalesTotals.length) {
    return <p>No Data found</p>;
  }
  const data = {
    labels: staffSalesTotals.map(staff => staff.storeman),
    data: staffSalesTotals.map(staff => staff.total),
  };

  return (
    <div>
      <ReDoughnutChart
        currencySymbol="$"
        isCurrency={true}
        linkTo={'/staff'}
        centerText="Total sales"
        chartData={data}
        headline="Staff Sales"
        tooltipFormatter={currencyFormat}
      />
    </div>
  );
};

export default withRouter(StaffSalesDonut);
