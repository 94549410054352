import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SalesTotalsTable from './tables/SalesTotalsTable';

class SalesTotals extends Component {
  render() {
    if (!this.props.sales.length) {
      return (
        <Paper>
          <Typography align="left" variant="headline" gutterBottom>
            Sales Totals
          </Typography>
          <Typography variant="button">No data available</Typography>
        </Paper>
      );
    }
    return (
      <Card>
        <CardContent>
          <Typography align="left" variant="headline" gutterBottom>
            Sales Totals
          </Typography>
          <SalesTotalsTable data={this.props.sales} />
        </CardContent>
      </Card>
    );
  }
}

export default SalesTotals;
