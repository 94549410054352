import React from 'react';
import { withRouter } from 'react-router-dom';
import ReDoughnutChart from './charts/reDoughnut';
import { currencyFormat } from '../constants';
import * as AircraftType from './utils/aircraftTypes';
import { mapData } from './salesByAircraft';

/**
 * Aircraft types to be shown on the chart
 * @type {number[]}
 */
const types = [
  // All types except Military
  AircraftType.Unspecified,
  AircraftType.Narrow,
  AircraftType.Wide,
  AircraftType.Rotary,
  AircraftType.Regional,
  AircraftType.BizJet
];

const AircraftDonut = ({ aircraftSales, link, title, totalText }) => {
  if (!Object.keys(aircraftSales).length) {
    return <p>No Data found</p>;
  }

  const data = mapData(aircraftSales, types);

  return (
    <div>
      <ReDoughnutChart
        currencySymbol="$"
        isCurrency={true}
        linkTo={'/sales'}
        centerText="Total sales"
        chartData={data}
        headline="Sales by aircraft type"
        tooltipFormatter={currencyFormat}
        totalText={totalText}
      />
    </div>
  );
};

export default withRouter(AircraftDonut);
