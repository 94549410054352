import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../Loader';
import SalesStaffDonut from '../staffSalesDonut';
import StaffSalesBarChart from '../StaffSalesBarChart';
import StaffSalesHistorical from '../tables/StaffSalesHistorical';
import shortid from 'shortid';
import StaffSalesSummary from '../StaffSalesSummary';
import StaffSalesLine from '../StaffSalesLine';
import StaffProfitsLine from '../StaffProfitsLine';
import StaffProfitsSummary from '../StaffProfitsSummary';
import Typography from '@material-ui/core/Typography';
class Staff extends React.Component {
  componentDidMount() {
    this.props.open();
  }

  render() {
    const { staffSales, staffProfits, staffProfitsSummary, staffSalesSummary } = this.props;
    if (!staffSales.dates || !staffSalesSummary.length) {
      return <Loader />;
    }
    return (
      <div>
        <Typography variant="display1" align="left" gutterBottom>
          Staff
        </Typography>
        <Grid container spacing={24} justify="center" direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardContent>
                <StaffSalesSummary data={staffSalesSummary} key="0" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SalesStaffDonut staffSalesTotals={staffSalesSummary} key="0" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardContent>
                <StaffSalesLine dataset={staffSales} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardContent>
                <StaffSalesBarChart staffSalesTotals={staffSalesSummary} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            {staffSales ? (
              <StaffSalesHistorical
                sales={staffSales}
                key={shortid.generate()}
              />
            ) : (
              <Loader />
            )}
          </Grid>
          {this.props.user.user && ['management', 'administrator', 'nls'].includes(this.props.user.user.role) ?
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent>
                  <StaffProfitsLine dataset={staffProfits} />
                </CardContent>
              </Card>
            </Grid> : ''
          }
          {this.props.user.user && ['management', 'administrator', 'nls'].includes(this.props.user.user.role) ?
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent>
                  <StaffProfitsSummary data={staffProfitsSummary} key="0"/>
                </CardContent>
              </Card>
            </Grid> : ''
          }
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: 'staff' }),
  };
};

export default connect(
  store => {
    return {
      staffSales: store.sales.staff,
      staffProfits: store.sales.staffProfits,
      staffProfitsSummary: store.sales.staffProfitsSummary,
      staffSalesSummary: store.sales.summary,
      user: store.user,
    };
  },
  mapDispatchToProps
)(Staff);
