import React from 'react';
import ReLineChart from './charts/reLine';

const StockValuationsLine = ({ data }) => {
  const newData = [
    {
      label: 'Value (USD)',
      data: data.map(item => ({ x: item.date, y: item.value })),
    },
  ];

  return (
    <div>
      <ReLineChart
        domain={['dataMin - 50000', 'auto']}
        margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        heading={'Stock Valuation over time'}
        dataset={newData}
      />
    </div>
  );
};
export default StockValuationsLine;
