import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { longIntFormat } from '../../constants';

const colors = [
  'rgba(2, 157, 175, 1)',
  'rgba(28, 204, 191, 1)',
  'rgba(248, 172, 27, 1)',
  'rgba(248, 138, 43, 1)',
  'rgba(38, 173, 222, 1)',
  'rgba(28, 199, 120, 1)',
  'rgba(211, 217, 39, 1)',
  'rgba(163, 161, 251, 1)',
  'rgba(138, 139, 168, 1)',
  'rgba(55, 149, 230, 1)',
  'rgba(2, 175, 148, 1)',
  'rgba(248, 97, 62, 1)',
  'rgba(194, 70, 224, 1)',
  'rgba(144, 190, 142, 1)',
  'rgba(245, 97, 166, 1)',
];

const chart = args => {
  const formattedDataSet = [];
  const allKeys = [];
  args.dataset.forEach((item, i) => {
    allKeys[i] = args.dataset[i].label;
    args.dataset[i].data.forEach((subItem, k) => {
      if (formattedDataSet[k] === undefined) {
        formattedDataSet[k] = {};
      }
      formattedDataSet[k].x = subItem.x;
      formattedDataSet[k][item.label] = subItem.y;
    });
  });

  return {
    chartData: formattedDataSet,
    keys: allKeys,
  };
};

const CustomTooltip = args => {
  return (
    <div className="custom-chart-tooltip">
      <table style={{ textAlign: 'left' }}>
        {args.payload.length > 0 && (
          <thead>
            <tr>
              <th>{args.payload[0].payload.x}</th>
            </tr>
          </thead>
        )}
        {args.payload.map((value, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td style={{ color: value.color, fontWeight: 'bold' }}>
                  {value.dataKey}:
                </td>
                <td>${longIntFormat.format(value.value)}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

const ReLineChart = args => {
  const containerStyle = {
    paddingBottom: '60%',
    position: 'relative',
  };
  var domainType = args.domain;
  if (domainType === undefined) {
    domainType = ['dataMin', 'dataMax'];
  }
  var scaleType = args.scale;
  if (scaleType === undefined) {
    scaleType = 'auto';
  }
  var margin = args.margin;
  if (margin === undefined) {
    margin = { top: 20, right: 0, left: -15, bottom: 0 };
  }
  if (args.isPresentation) {
    margin = { top: 20, right: 75, left: 75, bottom: 10 };
  }
  return (
    <div>
      {args.onToggle !== undefined ? (
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography align="left" variant="headline" gutterBottom>
              {args.heading}
            </Typography>
          </Grid>
          <Grid item xs={6} container direction="row-reverse">
            <FormGroup row>
              <FormControlLabel
                className="switch-button"
                control={<Switch onChange={args.onToggle} />}
                label="Show Negatives"
              />
            </FormGroup>
          </Grid>
        </Grid>
      ) : args.isPresentation ? (
        <div>
          <Typography
            align="center"
            variant="display2"
            style={{ marginBottom: '0.5em' }}
          >
            {args.heading}
          </Typography>
          {args.subHeading !== undefined && (
            <Typography
              align="center"
              variant="display1"
              style={{ marginBottom: '1em' }}
            >
              {args.subHeading}
            </Typography>
          )}
        </div>
      ) : (
        <Typography align="left" variant="headline">
          {args.heading}
        </Typography>
      )}
      <div className="chart-wrap" style={containerStyle}>
        <ResponsiveContainer>
          <AreaChart
            data={chart(args).chartData}
            syncId="anyId"
            margin={margin}
          >
            <defs>
              {chart(args).keys.map((item, index) => {
                return (
                  <linearGradient
                    id={'id-' + index}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                    key={index}
                  >
                    <stop
                      offset="0%"
                      stopColor={colors[index]}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={colors[index]}
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                );
              })}
            </defs>
            <CartesianGrid
              stroke="#EAF0F4"
              strokeWidth={args.isPresentation ? 2 : 1}
            />

            {chart(args).keys.map((item, index) => {
              return (
                <Area
                  animationDuration={
                    args.animationDuration === undefined
                      ? 1500
                      : args.animationDuration
                  }
                  type="monotone"
                  dataKey={item}
                  stroke={colors[index]}
                  strokeWidth="2"
                  activeDot={{ r: 8 }}
                  fill={'url(#id-' + index + ')'}
                  key={index}
                />
              );
            })}

            <XAxis
              minTickGap={args.isPresentation ? 70 : 5}
              stroke="#575E7B"
              strokeWidth={args.isPresentation ? 2 : 1}
              dataKey="x"
              dy={args.isPresentation ? 10 : 0}
              tick={{
                fill: '#575E7B',
                fontFamily: 'Open Sans',
                fontSize: args.isPresentation ? 28 : 10,
                fontWeight: args.isPresentation ? 700 : 400,
              }}
            />

            <YAxis
              stroke="#575E7B"
              strokeWidth={args.isPresentation ? 2 : 1}
              domain={domainType}
              allowDataOverflow
              tick={{
                fontFamily: 'Open Sans',
                fontSize: args.isPresentation ? 28 : 10,
                fill: '#575E7B',
                fontWeight: args.isPresentation ? 700 : 400,
              }}
            />

            <Legend
              verticalAlign="top"
              iconSize={args.isPresentation ? 30 : 14}
              height={36}
            />
            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReLineChart;
