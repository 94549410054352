import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SalesByCountryTable from './tables/SalesByCountryTable';

class SalesByCountry extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      includeQuoted: true,
    };
  }

  render() {
    if (!Object.keys(this.props.countrySales).length) {
      return (
        <Paper>
          <Typography align="center" variant="headline" gutterBottom>
            {this.props.title || '10 countries with highest sales'}
          </Typography>
          <Typography variant="button">No data available</Typography>
        </Paper>
      );
    }
    return (
      <Card>
        <CardContent className={'presentation-table ' + this.props.color}>
          <div>
            <Typography
              align="center"
              variant="display2"
              style={{ marginBottom: '0.5em' }}
            >
              {this.props.heading}
            </Typography>
            <Typography
              align="center"
              variant="display1"
              style={{ marginBottom: '1em' }}
            >
              {this.props.subHeading}
            </Typography>
          </div>
          <SalesByCountryTable data={this.props.countrySales} />
        </CardContent>
      </Card>
    );
  }
}

export default SalesByCountry;
