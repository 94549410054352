import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { longIntFormat } from '../../constants';
import {
  ResponsiveContainer,
  PieChart,
  Label,
  Pie,
  Legend,
  Tooltip,
} from 'recharts';

const ReDoughnutChart = ({
  headline,
  linkTo,
  chartData,
  isCurrency,
  currencySymbol,
  totalText,
  isPresentation,
  centerText,
  subHeading,
}) => {
  if (centerText === undefined) {
    centerText = ' ';
  }
  if (isCurrency === undefined) {
    isCurrency = false;
  }
  if (currencySymbol === undefined) {
    currencySymbol = '$';
  }
  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
      },
    ],
  };

  const colors = {
    bg: [
      'rgba(2, 157, 175, 1)',
      'rgba(28, 204, 191, 1)',
      'rgba(248, 172, 27, 1)',
      'rgba(248, 138, 43, 1)',
      'rgba(38, 173, 222, 1)',
      'rgba(28, 199, 120, 1)',
      'rgba(211, 217, 39, 1)',
      'rgba(163, 161, 251, 1)',
      'rgba(138, 139, 168, 1)',
      'rgba(55, 149, 230, 1)',
      'rgba(2, 175, 148, 1)',
      'rgba(248, 97, 62, 1)',
      'rgba(194, 70, 224, 1)',
      'rgba(144, 190, 142, 1)',
      'rgba(245, 97, 166, 1)',
    ],
  };

  const formatData = data => {
    const formattedData = [];
    var totalEnquiries = 0;
    data.datasets.forEach((item, i) => {
      item.data.forEach((subItem, k) => {
        formattedData[k] = {
          name: data.labels[k],
          value: parseFloat(subItem),
          fill: colors.bg[k],
        };
        totalEnquiries += parseFloat(subItem);
      });
    });

    return {
      data: formattedData,
      total: totalEnquiries,
    };
  };

  const title = linkTo ? (
    <Link className="titleLink" to={linkTo}>
      {headline}
    </Link>
  ) : (
    { headline }
  );

  const containerStyle = {
    paddingBottom: '60%',
    position: 'relative',
  };

  const getPieValue = () => {
    if (totalText) {
      return totalText;
    }

    return isCurrency
      ? currencySymbol +
      longIntFormat.format(formatData(data).total)
      : formatData(data).total
  }

  const CustomTooltip = args => {
    return (
      <div className="custom-chart-tooltip">
        {args.payload.map((value, index) => {
          return (
            <div key={index}>
              <p style={{ color: value.payload.fill, fontWeight: 'bold' }}>
                {value.payload.name}
              </p>
              <p>
                {isCurrency
                  ? '$' + longIntFormat.format(value.value)
                  : value.value}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {isPresentation ? (
        <div>
          <Typography
            align="center"
            variant="display2"
            style={{ marginBottom: subHeading !== undefined ? '0.5em' : '1em' }}
          >
            {title}
          </Typography>
          {subHeading !== undefined && (
            <Typography
              align="center"
              variant="display1"
              style={{ marginBottom: '1em' }}
            >
              {subHeading}
            </Typography>
          )}
        </div>
      ) : (
        <Typography align="left" variant="headline" children={title} />
      )}
      <div style={containerStyle} className="chart-wrap">
        <ResponsiveContainer>
          <PieChart margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
            {isPresentation ? (
              <Legend
                iconType="circle"
                iconSize="35"
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            ) : (
              <Legend
                iconType="circle"
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            )}
            {isPresentation ? (
              <Pie
                dataKey="value"
                cx="30%"
                strokeWidth={0}
                data={formatData(data).data}
                innerRadius="50%"
                outerRadius="90%"
              >
                <Label
                  className="pie-label-presentation"
                  value={
                    isCurrency
                      ? currencySymbol +
                        longIntFormat.format(formatData(data).total)
                      : formatData(data).total
                  }
                  position="center"
                />
              </Pie>
            ) : (
              <Pie
                dataKey="value"
                strokeWidth={0}
                cx="35%"
                data={formatData(data).data}
                innerRadius="43%"
                outerRadius="70%"
              >
                <Label
                  className="pie-label"
                  value={getPieValue()}
                  position="center"
                />
                <Label
                  className="pie-label"
                  value={centerText}
                  position="center"
                />
              </Pie>
            )}
            <Tooltip content={<CustomTooltip isCurrency={isCurrency} />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default withRouter(ReDoughnutChart);

ReDoughnutChart.propTypes = {
  headline: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  chartData: PropTypes.object.isRequired,
};

ReDoughnutChart.defaultProps = {
  chartData: [],
  title: 'Please supply a title',
};
