import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const styles = {};

class MobileDateFilterDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleChange = e => {
    this.props.onChange(e);
  };

  onSubmit = e => {
    this.props.updateMobileData();

    this.props.onClose();
  };

  render() {
    const {
      classes,
      onClose,
      endDate,
      cohort,
      updateMobileData,
      startDate,
      selectedValue,
      showPeriod,
      onChange,
      ...other
    } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">Filter results</DialogTitle>
        <DialogContent>
          <TextField
            name="start_date"
            label="Start date"
            type="date"
            margin="normal"
            value={this.props.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              this.handleChange(e);
            }}
            style={{ width: '100%' }}
          />
          <TextField
            name="end_date"
            label="End date"
            type="date"
            style={{ width: '100%' }}
            value={this.props.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => this.props.onChange(e)}
          />
          {showPeriod ? (
            <FormControl margin="dense" style={{ width: '100%' }}>
              <InputLabel>Period</InputLabel>
              <Select
                native
                name="cohort"
                value={this.props.cohort}
                label="Period"
                style={{ width: '100%' }}
                onChange={this.props.onChange}
              >
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </Select>
            </FormControl>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.onSubmit} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MobileDateFilterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  cohort: PropTypes.string.isRequired,
  updateMobileData: PropTypes.func,
};

export default withStyles(styles)(MobileDateFilterDialog);
