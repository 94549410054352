import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import PartsEnquired from '../PartsEnquired';
import OrderBook from '../OrderBook';
import SalesTotals from '../SalesTotals';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PartsEnquiredDonut from '../PartEnquiredDonut';
import SalesStaffDonut from '../staffSalesDonut';
import SalesLine from '../SalesLine';
import Typography from '@material-ui/core/Typography';
import StaffSalesBarChart from '../StaffSalesBarChart';
import GenericError from '../GenericError';
import SalesMap from '../SalesMap';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuotedFiltered: false,
    };

    this.props.open();
  }

  niceDate() {
    return moment();
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onPartsEnquiredQuotedFilter(e) {
    this.setState({ isQuotedFiltered: e.target.checked });
  }

  render() {
    const {
      classes,
      sales,
      partsEnquired,
      partsEnquiredAll,
      partsQuoted,
      staffSalesSummary,
      orders,
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="display1" align="left" gutterBottom>
          Overview
        </Typography>
        <Grid container spacing={24} justify="center" direction="row">
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SalesLine dataset={sales} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SalesMap className="map" title="Sales by Country ($)" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent style={{ height: '100%' }}>
                {partsEnquired.length === 0 ? (
                  <GenericError title={'Top 5 Parts Enquired'} />
                ) : (
                  <PartsEnquiredDonut
                    parts={partsEnquired.slice(0, 5)}
                    key="0"
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            {sales.length === 0 ? (
              <GenericError title={'Number Of Sales'} />
            ) : (
              <SalesTotals sales={sales} />
            )}
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            {(partsEnquired.length && (
              <PartsEnquired
                parts={
                  this.state.isQuotedFiltered ? partsEnquiredAll : partsEnquired
                }
                showQuotedFilter
                onQuotedChange={e => this.onPartsEnquiredQuotedFilter(e)}
              />
            )) || (
              <Card>
                <CardContent style={{ height: '100%' }}>
                  <GenericError title="Parts Enquired" />
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            {(partsEnquired.length && (
              <PartsEnquired
                align="left"
                parts={partsQuoted}
                title="Parts Quoted"
              />
            )) || (
              <Card>
                <CardContent style={{ height: '100%' }}>
                  <GenericError title="Parts Quoted" />
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <SalesStaffDonut staffSalesTotals={staffSalesSummary} key="0" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Card>
              <CardContent>
                <StaffSalesBarChart
                  staffSalesTotals={staffSalesSummary}
                  key="0"
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            {orders.length && <OrderBook orders={orders} title="Order Book" />}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing.unit * 2,
  },
  textfield: {
    marginTop: theme.spacing.unit * 4,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const mapDispatchToProps = dispatch => {
  return {
    open: () => dispatch({ type: 'NAVIGATE', target: '' }),
  };
};

export default connect(
  store => {
    return {
      sales: store.sales.all,
      partsEnquired: store.parts.partsEnquired,
      partsEnquiredAll: store.parts.partsEnquiredAll,
      partsQuoted: store.parts.partsQuoted,
      staffSales: store.sales.staff,
      staffSalesSummary: store.sales.summary,
      orders: store.orders.all,
    };
  },
  mapDispatchToProps
)(withStyles(styles)(Home));
